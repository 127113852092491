/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import addProviderAction from '../../../redux/actions/providers/addProviders';
import validator from '../../../helpers/validator';

const Modal = ({
  addProviderAction: addAction,
  addProvider,
  refetch,
  banksData,
}) => {
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [bankCode, setBankCode] = useState(0);
  const [bankName, setBankName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [type, setType] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setLoading(false);
    setErrors([]);

    if (addProvider.status === 'success') {
      setLoading(false);
      $('#addProviderModal').modal('hide');
      setTitle('');
      setEmail('');
      setBankName('');
      setBankCode(0);
      setType(0);
      setAccountNumber('');
      setErrors([]);
      refetch();
    }
    if (addProvider.status === 'error') {
      setLoading(false);
      return setErrors([addProvider.error.message]);
    }

    return undefined;
  }, [addProvider]);

  const handleSubmit = async () => {
    const bankObj = {
      bankName,
      bankCode,
      accountNumber: bankCode === 'MPS' ? `250${accountNumber}` : accountNumber,
    };
    const data = { name: title, type, email };
    data.bank = bankObj;

    if (
      !bankName ||
      !bankCode ||
      !title ||
      !type ||
      (bankCode !== 'MPS' && !accountNumber)
    ) {
      return setErrors(['All fields are required!']);
    }

    if (bankCode === 'MPS') {
      const validationErrors = await validator({ accountNumber, email });
      if (validationErrors.length > 0) return setErrors(validationErrors);
    }

    setErrors([]);
    setLoading(true);
    return addAction(data);
  };

  const handleBankChange = (e) => {
    const val = e.target.value;
    const filterBank = banksData.filter((elt) => elt.code === val);
    const { name } = filterBank[0];
    setBankCode(val);
    setBankName(name);
  };

  return (
    <>
      <div>
        {errors.map((error, index) => (
          <div
            className="alert alert-danger text-center my-2"
            role="alert"
            key={index}
          >
            {error}
            <button
              type="button"
              className="close"
              onClick={() => setErrors([])}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ))}
      </div>
      <form>
        <div className="form-group">
          <select
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="custom-select form-control authInput cursor-pointer"
          >
            <option disabled defaultValue value={0}>
              Provider Type
            </option>

            <option value="Record Label">Record Label</option>
            <option value="Music Distributor">Music Distributor</option>
          </select>
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control authInput"
            placeholder="Provider name"
            style={{ fontSize: 15 }}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control authInput"
            placeholder="Provider email"
            style={{ fontSize: 15 }}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
        </div>
        <div className="form-group">
          <select
            className="custom-select form-control authInput cursor-pointer"
            value={bankCode}
            onChange={handleBankChange}
          >
            <option disabled defaultValue value={0}>
              Choose Bank
            </option>
            {banksData.map((elt) => (
              <option value={elt.code} key={elt.id}>
                {elt.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          {bankCode === 'MPS' ? (
            <div className="input-group">
              <div className="input-group-prepend">
                <span
                  className="input-group-text text-light-dark bg-secondary border-primary"
                  style={{ fontSize: 15 }}
                >
                  +250
                </span>
              </div>
              <input
                type="text"
                className="form-control authInput"
                placeholder="Phone Number"
                value={accountNumber}
                style={{ fontSize: 15 }}
                onChange={(e) => {
                  setAccountNumber(e.target.value);
                }}
                maxLength="9"
              />
            </div>
          ) : (
            <input
              type="text"
              className="form-control authInput"
              style={{ fontSize: 15 }}
              placeholder="Enter your Account number"
              required
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              disabled={loading}
            />
          )}
        </div>
      </form>
      <div className="text-center">
        <button
          type="button"
          className="btn btn-primary px-4 py-1 ml-2 mt-3"
          onClick={handleSubmit}
          disabled={!!loading}
        >
          Add
          {loading ? (
            <span className="spinner-border spinner-border-sm text-dark ml-2" />
          ) : null}
        </button>
      </div>
    </>
  );
};

Modal.propTypes = {
  refetch: PropTypes.func.isRequired,
  addProviderAction: PropTypes.func.isRequired,
  addProvider: PropTypes.objectOf(PropTypes.any).isRequired,
  banksData: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    )
  ).isRequired,
};

const mapStateToProps = ({ addProvider }) => ({ addProvider });

export default connect(mapStateToProps, { addProviderAction })(Modal);
