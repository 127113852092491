/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

const Table = ({ data }) => (
  <>
    <div className="border rounded">
      <table className="table text-white text-center mb-0">
        <thead>
          <tr>
            <th scope="col">Artist</th>
            <th scope="col">Streams</th>
            <th scope="col">Revenue</th>
            <th scope="col">%</th>
          </tr>
        </thead>
        <tbody>
          {data.map((payout) => (
            <tr
              key={
                typeof payout.artist === 'object'
                  ? payout.artist.data._id
                  : payout._id
              }
            >
              <td>
                {typeof payout.artist === 'object'
                  ? payout.artist.data.name
                  : payout.artistName}
              </td>
              <td>
                {typeof payout.artist === 'object'
                  ? payout.artist.streams
                  : payout.totalStreams}
              </td>
              <td>
                {typeof payout.artist === 'object'
                  ? payout.totalAmount
                  : payout.amount}
              </td>
              <td>
                {typeof payout.artist === 'object'
                  ? `${payout.artist.percentageStreams.toFixed(2)} %`
                  : `${payout.percentage} %`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
);

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any]))
  ).isRequired,
};

export default Table;
