/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import removeTracksAction from '../../../redux/actions/tracks/removeTracks';

const Modal = ({
  id,
  removeTracksAction: removeAction,
  removeTracks,
  data,
  playlistId,
  refetch,
}) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setLoading(false);
    setErrors([]);

    if (removeTracks.status === 'success') {
      setLoading(false);
      $(`#removeTracksModal${id}`).modal('hide');
      setErrors([]);
      refetch();
    }
    if (removeTracks.status === 'error') {
      setLoading(false);
      return setErrors([removeTracks.error.message]);
    }

    return undefined;
  }, [removeTracks]);

  const handleSubmit = () => {
    const payload = { track: id };

    setErrors([]);
    setLoading(true);
    return removeAction(playlistId, payload);
  };

  const closeModal = () => {
    $(`#removeTracksModal${id}`).modal('hide');
  };

  return (
    <>
      <button
        className="btn btn-danger px-2 py-1 text-white"
        type="button"
        data-toggle="modal"
        data-target={`#removeTracksModal${id}`}
      >
        <FontAwesomeIcon icon={['fas', 'trash-alt']} />
      </button>
      <div
        className="modal fade"
        id={`removeTracksModal${id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`removeTracksModal${id}`}
        aria-hidden="true"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ width: '100%' }}
          >
            <div
              className="modal-content bg-secondary text-white border-0 artist-modal"
              style={{ margin: 0 }}
            >
              <div className="modal-header">
                <h6
                  className="modal-title mx-auto text-center"
                  id={`removeTracksModal${id}`}
                >
                  Remove Track
                </h6>
                <span
                  onClick={closeModal}
                  className="close-button cursor-pointer text-center"
                >
                  &times;
                </span>
              </div>
              <div className="px-3 pt-0 modal-body">
                {errors.map((error, index) => (
                  <div
                    className="alert alert-danger text-center my-2"
                    role="alert"
                    key={index}
                  >
                    {error}
                    <button
                      type="button"
                      className="close"
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
                <p
                  className="my-2 text-center"
                  style={{ fontSize: 15, color: '#ccc' }}
                >
                  Are you sure, you want to remove
                  <b className="mx-1">{data.title}</b>
                  by
                  <b className="mx-1">{data.artist.name}</b>
                  from this playlist?
                </p>
                <div className="text-right">
                  <button
                    type="button"
                    className="btn btn-secondary px-3 py-1 ml-2 mt-3"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary px-3 py-1 ml-2 mt-3"
                    onClick={handleSubmit}
                    disabled={!!loading}
                  >
                    Proceed
                    {loading ? (
                      <span className="spinner-border spinner-border-sm text-dark ml-2" />
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  refetch: PropTypes.func.isRequired,
  removeTracksAction: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  playlistId: PropTypes.string.isRequired,
  removeTracks: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ removeTracks }) => ({ removeTracks });

export default connect(mapStateToProps, { removeTracksAction })(Modal);
