import axios, { config } from '..';
import {
  FETCH_PAYOUTS_SUCCESS,
  FETCH_PAYOUTS_FAILED,
  CLEAR_PAYOUTS,
  CLEAR_PAY_ARTISTS,
  CLEAR_RETRY_PAYOUTS,
} from '../../actionTypes';

export default (month, year) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_PAYOUTS });
    dispatch({ type: CLEAR_PAY_ARTISTS });
    dispatch({ type: CLEAR_RETRY_PAYOUTS });

    const response = await axios.get(
      `/payouts/stats/?month=${month}&year=${year}`,
      config
    );

    const {
      data: { message, data },
    } = response;
    dispatch({
      type: FETCH_PAYOUTS_SUCCESS,
      message,
      results: data,
    });
  } catch (err) {
    let error = {};
    if (err.response) {
      const {
        data: { status, message },
      } = err.response;
      error = { status, message };
    } else {
      error = {
        status: 500,
        message: err.message,
      };
    }
    dispatch({ type: FETCH_PAYOUTS_FAILED, error });
  }
};
