/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withSidebar from '../../withSidebar';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import Table from '../../components/Tables/FeedsTable';
import fetchAllFeedsAction from '../../redux/actions/feed/fetchAllFeeds';
import AddFeed from '../../components/modals/AddFeed';

const Feed = ({ fetchAllFeedsAction: fetchAction, fetchFeeds }) => {
  const history = useHistory();
  const [status, setStatus] = useState('initial');
  const [resultsData, setResultsData] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem('INGOMA_ADMIN_TOKEN')) return history.push('/');
    if (status === 'initial') {
      fetchAction();
      setStatus('fetching');
    }

    if (fetchFeeds.status === 'clear') {
      setStatus('fetching');
      setResultsData([]);
    }

    if (fetchFeeds.status === 'success') {
      setStatus('success');
      setResultsData(fetchFeeds.results);
    }

    if (fetchFeeds.status === 'error') {
      const { error } = fetchFeeds;
      if (error.status === 500) setStatus('unknown_error');
      if (error.status === 404) setStatus('no_data');
    }
    return undefined;
  }, [fetchFeeds]);

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      case 'no_data':
        data = (
          <Error title="No data found!" description="No Feeds added yet!" />
        );
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  const refetch = () => {
    setStatus('fetching');
    return fetchAction();
  };

  return (
    <>
      <div className="py-3">
        <div className="row py-2 mt-4 mb-3">
          <div className="col-4">
            <button
              className="btn btn-primary px-3 py-1 text-dark"
              type="button"
              data-toggle="modal"
              data-target="#addFeedModal"
              disabled={status === 'fetching'}
            >
              Add Feed +
            </button>
            <AddFeed refetch={refetch} />
          </div>
        </div>
        <DisplayData>
          <Table data={resultsData} refetch={refetch} />
        </DisplayData>
      </div>
    </>
  );
};

Feed.propTypes = {
  fetchAllFeedsAction: PropTypes.func.isRequired,
  fetchFeeds: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ fetchFeeds }) => ({ fetchFeeds });

export default connect(mapStateToProps, { fetchAllFeedsAction })(
  withSidebar(Feed, 'feed')
);
