/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import fetchArtists from '../../../../redux/actions/artists/getPlatformArtists';
import AddForm from './AddForm';

const Modal = ({ playlistId, refetch }) => {
  const [status, setStatus] = useState('initial');
  const [resultsData, setResultsData] = useState([]);

  const getArtists = async () => {
    const platformArtists = await fetchArtists();
    setResultsData(platformArtists);
    setStatus('');
  };

  useEffect(() => {
    if (status === 'initial') getArtists();

    return undefined;
  });

  const closeModal = () => {
    $('#addArtistsModal').modal('hide');
  };

  return (
    <>
      <div
        className="modal fade"
        id="addArtistsModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addArtistsModal"
        aria-hidden="true"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ width: '100%' }}
          >
            <div
              className="modal-content bg-secondary text-white border-0 tracks-modal-content"
              style={{ margin: 0 }}
            >
              <div className="modal-header">
                <h6
                  className="modal-title mx-auto text-center"
                  id="addArtistsModal"
                >
                  Add Artists
                </h6>
                <span
                  onClick={closeModal}
                  className="close-button cursor-pointer text-center"
                >
                  &times;
                </span>
              </div>
              <div className="px-3 pt-0 modal-body">
                <AddForm
                  tracksData={resultsData}
                  refetch={refetch}
                  playlistId={playlistId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  playlistId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default Modal;
