/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import addTracksAction from '../../../redux/actions/tracks/addTracks';
import TagInput from './TagInput';

const Modal = ({
  addTracksAction: addAction,
  addTracks,
  refetch,
  playlistId,
  tracksData = [],
}) => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setLoading(false);
    setErrors([]);

    if (addTracks.status === 'success') {
      setLoading(false);
      $('#addTrackModal').modal('hide');
      setErrors([]);
      refetch();
    }
    if (addTracks.status === 'error') {
      setLoading(false);
      return setErrors([addTracks.error.message]);
    }

    return undefined;
  }, [addTracks]);

  const handleSubmit = () => {
    const data = { tracks: [] };
    tags.forEach((tag) => {
      data.tracks.push(tag.id);
    });
    setErrors([]);
    setLoading(true);
    return addAction(playlistId, data);
  };

  return (
    <div>
      {errors.map((error, index) => (
        <div
          className="alert alert-danger text-center my-2"
          role="alert"
          key={index}
        >
          {error}
          <button type="button" className="close" onClick={() => setErrors([])}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ))}
      <form>
        <div className="form-group" disabled>
          <TagInput
            tags={tags}
            setTags={setTags}
            suggestions={tracksData}
            placeholder="Search song"
          />
        </div>
      </form>
      <div className="text-center">
        <button
          type="button"
          className="btn btn-primary px-5 py-1 ml-2 mt-3"
          onClick={handleSubmit}
          disabled={!!loading}
        >
          Add
          {loading ? (
            <span className="spinner-border spinner-border-sm text-dark ml-2" />
          ) : null}
        </button>
      </div>
    </div>
  );
};

Modal.propTypes = {
  playlistId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  addTracksAction: PropTypes.func.isRequired,
  addTracks: PropTypes.objectOf(PropTypes.any).isRequired,
  tracksData: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any]))
  ).isRequired,
};

const mapStateToProps = ({ addTracks }) => ({ addTracks });

export default connect(mapStateToProps, { addTracksAction })(Modal);
