/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import addPlaylistAction from '../../../redux/actions/playlists/addPlaylist';

const Modal = ({
  addPlaylistAction: addAction,
  addPlaylist,
  refetch,
  setErrors,
}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);

    if (addPlaylist.status === 'success') {
      setLoading(false);
      $('#addPlaylistModal').modal('hide');
      setTitle('');
      setDescription('');
      setErrors([]);

      refetch();
    }
    if (addPlaylist.status === 'error') {
      setLoading(false);
      return setErrors([addPlaylist.error.message]);
    }

    return undefined;
  }, [addPlaylist]);

  const handleSubmit = () => {
    const data = { title, description };
    if (!title || !description) {
      return setErrors(['All fields are required!']);
    }
    setErrors([]);
    setLoading(true);
    return addAction('manual', data);
  };

  return (
    <div className="mt-3">
      <form>
        <div className="form-group">
          <input
            type="text"
            className="form-control authInput"
            placeholder="Enter playlist title"
            style={{ fontSize: 15 }}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            required
          />
        </div>
        <div className="form-group">
          <textarea
            className="form-control authInput"
            placeholder="Enter description"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            required
          />
        </div>
      </form>
      <div className="text-center">
        <button
          type="button"
          className="btn btn-primary px-3 py-1 ml-2 mt-3"
          onClick={handleSubmit}
          disabled={!!loading}
        >
          Add
          {loading ? (
            <span className="spinner-border spinner-border-sm text-dark ml-2" />
          ) : null}
        </button>
      </div>
    </div>
  );
};

Modal.propTypes = {
  setErrors: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  addPlaylistAction: PropTypes.func.isRequired,
  addPlaylist: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ addPlaylist }) => ({ addPlaylist });

export default connect(mapStateToProps, { addPlaylistAction })(Modal);
