import axios, { config } from '..';
import {
  FETCH_CHARTS_SUCCESS,
  FETCH_CHARTS_FAILED,
  CLEAR_FETCH_CHARTS,
  CLEAR_ADD_CHART,
  CLEAR_UPDATE_CHART,
  CLEAR_ADD_CHART_TO_FEED,
  CLEAR_REMOVE_CHART_FROM_FEED,
} from '../../actionTypes';

export default () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_FETCH_CHARTS });
    dispatch({ type: CLEAR_ADD_CHART });
    dispatch({ type: CLEAR_UPDATE_CHART });
    dispatch({ type: CLEAR_ADD_CHART_TO_FEED });
    dispatch({ type: CLEAR_REMOVE_CHART_FROM_FEED });

    const response = await axios.get('/charts/all', config);

    const {
      data: { message, data },
    } = response;
    dispatch({
      type: FETCH_CHARTS_SUCCESS,
      message,
      results: data,
    });
  } catch (err) {
    let error = {};
    if (err.response) {
      const {
        data: { status, message },
      } = err.response;
      error = { status, message };
    } else {
      error = {
        status: 500,
        message: err.message,
      };
    }
    dispatch({ type: FETCH_CHARTS_FAILED, error });
  }
};
