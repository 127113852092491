import { combineReducers } from 'redux';

import admin from './admin';
import artists from './artists';
import payments from './payments';
import feed from './feed';
import playlists from './playlists';
import tracks from './tracks';
import charts from './charts';
import providers from './providers';

export default combineReducers({
  ...admin,
  ...payments,
  ...artists,
  ...feed,
  ...playlists,
  ...tracks,
  ...charts,
  ...providers,
});
