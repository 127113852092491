import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Placeholder from '../assets/images/placeHolder.png';
import PersonalInfo from './forms/PersonalInfo';
import Identification from './forms/Identification';
import DenyModal from './forms/DenyModal';
import BankInfo from './forms/BankInfo';
import verifyArtistsAction from '../redux/actions/artists/verifyArtists';
import denyArtistsAction from '../redux/actions/artists/denyArtists';

const DetailsModal = ({
  data,
  id,
  refetch,
  verifyArtists,
  verifyArtistsAction: verify,
  denyArtistsAction: deny,
  denyArtists,
}) => {
  const [loading, setLoading] = useState(true);
  const [denying, setDenying] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setVerifying(false);
    setDenying(false);
    setErrors([]);

    if (verifyArtists.status === 'verify_success') {
      setVerifying(false);
      $(`#detailsModal${id}`).modal('hide');
      setErrors([]);
      refetch();
    }
    if (verifyArtists.status === 'verify_error') {
      setVerifying(false);
      return setErrors([verifyArtists.error.message]);
    }

    if (denyArtists.status === 'deny_success') {
      setDenying(false);
      $(`#detailsModal${id}`).modal('hide');
      setErrors([]);
      refetch();
    }
    if (denyArtists.status === 'deny_error') {
      setDenying(false);
      return setErrors([denyArtists.error.message]);
    }

    return undefined;
  }, [verifyArtists, denyArtists]);

  const imgSrc = () => {
    if (loading) {
      return Placeholder;
    }
    if (data.profilePhotoLink) {
      return data.profilePhotoLink;
    }
    return Placeholder;
  };

  const handleVerify = () => {
    setVerifying(true);
    setErrors([]);
    return verify(id);
  };

  const handleDeny = (payload) => {
    setDenying(true);
    setErrors([]);
    return deny(payload, id);
  };

  return (
    <>
      <div className="modal fade" id={`detailsModal${id}`}>
        <div className="modal-dialog modal-md">
          <div className="details-modal-content modal-content bg-secondary  border-0">
            <div className="modal-header">
              <h6
                className="modal-title mx-auto text-center text-white"
                id={`detailsModal${id}`}
              >
                Artist&#39;s Information
              </h6>
              <span
                data-dismiss="modal"
                className="close-button cursor-pointer text-center"
              >
                &times;
              </span>
            </div>

            <div className="modal-body">
              {errors.map((error, index) => (
                <div
                  className="alert alert-danger text-center"
                  role="alert"
                  key={index}
                >
                  {error}
                  <button
                    type="button"
                    className="close"
                    onClick={() => setErrors([])}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ))}

              <div className="text-center">
                <img
                  style={{
                    objectFit: 'cover',
                    width: '100px',
                    height: '100px',
                  }}
                  className="rounded-circle"
                  src={imgSrc()}
                  alt="Artist Pic"
                  srcSet=""
                  onLoad={() => setLoading(false)}
                />
                <span
                  className="text-center text-primary d-block mt-2 mb-1"
                  style={{ fontSize: 13 }}
                >
                  {data.name}
                </span>
                <div className="bottom-bar" />
              </div>
              <PersonalInfo data={data} />
              <Identification data={data} />
              <BankInfo data={data} />
              {data.contract ? (
                <>
                  <p className="text-white mt-2" style={{ fontSize: 14 }}>
                    <span className="text-primary mr-2">N.B:</span>
                    This information was filled by a diiferent person other than
                    the artist.
                  </p>
                  <a
                    href={data.contract}
                    rel="noreferrer"
                    target="_blank"
                    style={{ fontSize: 15 }}
                    className="btn btn-primary px-4 py-1"
                  >
                    View Contract
                    <FontAwesomeIcon
                      icon={['fas', 'file-alt']}
                      className="auth-form-icon ml-2"
                    />
                  </a>
                </>
              ) : null}
            </div>
            {data.status === 'verified' || data.status === 'denied' ? null : (
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger py-1 px-4 mr-3"
                  disabled={
                    !!(denying || data.status === 'denied' || verifying)
                  }
                  data-toggle="modal"
                  data-target={`#denyModal${id}`}
                >
                  {denying ? (
                    <span>
                      Deny
                      <span className="spinner-border spinner-border-sm text-white ml-2" />
                    </span>
                  ) : (
                    <span>
                      Deny
                      <FontAwesomeIcon
                        icon={['fas', 'times-circle']}
                        className="auth-form-icon ml-2"
                      />
                    </span>
                  )}
                </button>
                <DenyModal action={handleDeny} id={id} />
                <button
                  type="button"
                  className="btn btn-success py-1 px-4"
                  disabled={!!(verifying || denying)}
                  onClick={handleVerify}
                >
                  {verifying ? (
                    <span>
                      Verify
                      <span className="spinner-border spinner-border-sm text-white ml-2" />
                    </span>
                  ) : (
                    <span>
                      Verify
                      <FontAwesomeIcon
                        icon={['fas', 'check-circle']}
                        className="auth-form-icon ml-2"
                      />
                    </span>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

DetailsModal.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  verifyArtists: PropTypes.objectOf(PropTypes.any).isRequired,
  denyArtists: PropTypes.objectOf(PropTypes.any).isRequired,
  verifyArtistsAction: PropTypes.func.isRequired,
  denyArtistsAction: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

const mapStateToProps = ({ verifyArtists, denyArtists }) => ({
  verifyArtists,
  denyArtists,
});

export default connect(mapStateToProps, {
  verifyArtistsAction,
  denyArtistsAction,
})(DetailsModal);
