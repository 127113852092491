import fetchPayouts from './fetchPayouts';
import retryPayouts from './retryPayouts';
import payArtists from './payArtists';
import getBanks from './getBanks';

export default {
  fetchPayouts,
  retryPayouts,
  payArtists,
  getBanks,
};
