/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import addSetsAction from '../../../../redux/actions/charts/addSets';
import TagInput from '../../tracks/TagInput';

const Modal = ({
  addSetsAction: addAction,
  addSets,
  refetch,
  playlistId,
  tracksData = [],
}) => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setLoading(false);
    setErrors([]);

    if (addSets.status === 'success') {
      setLoading(false);
      $('#addSetsModal').modal('hide');
      setErrors([]);
      refetch();
    }
    if (addSets.status === 'error') {
      setLoading(false);
      return setErrors([addSets.error.message]);
    }

    return undefined;
  }, [addSets]);

  const handleSubmit = () => {
    const data = { sets: [] };
    tags.forEach((tag) => {
      data.sets.push(tag.id);
    });
    setErrors([]);
    setLoading(true);
    return addAction(playlistId, data);
  };

  return (
    <div>
      {errors.map((error, index) => (
        <div
          className="alert alert-danger text-center my-2"
          role="alert"
          key={index}
        >
          {error}
          <button type="button" className="close" onClick={() => setErrors([])}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ))}
      <form>
        <div className="form-group" disabled>
          <TagInput
            tags={tags}
            setTags={setTags}
            suggestions={tracksData}
            placeholder="Search music set"
          />
        </div>
      </form>
      <div className="text-center">
        <button
          type="button"
          className="btn btn-primary px-5 py-1 ml-2 mt-3"
          onClick={handleSubmit}
          disabled={!!loading}
        >
          Add
          {loading ? (
            <span className="spinner-border spinner-border-sm text-dark ml-2" />
          ) : null}
        </button>
      </div>
    </div>
  );
};

Modal.propTypes = {
  playlistId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  addSetsAction: PropTypes.func.isRequired,
  addSets: PropTypes.objectOf(PropTypes.any).isRequired,
  tracksData: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any]))
  ).isRequired,
};

const mapStateToProps = ({ addSets }) => ({ addSets });

export default connect(mapStateToProps, { addSetsAction })(Modal);
