import * as yup from 'yup';

const validators = {
  email: yup
    .string()
    .email('Please use a valid email')
    .required('Please enter an email'),
  accountNumber: yup
    .string()
    .matches(/^[7][8][0-9]{7}$/, 'Tel must be 9 digits and start with "78..."'),
};

export default (value) => {
  const selected = {};
  Object.keys(value).forEach((key) => {
    selected[key] = validators[key];
  });
  return yup
    .object()
    .shape(selected)
    .validate(value, { abortEarly: false })
    .catch((err) => err.errors);
};
