import axios, { config } from '..';
import {
  FETCH_CHART_SUCCESS,
  FETCH_CHART_FAILED,
  CLEAR_FETCH_CHART,
  CLEAR_ADD_CHART_SETS,
  CLEAR_ADD_CHART_ARTISTS,
  CLEAR_REMOVE_CHART_ARTIST,
  CLEAR_REMOVE_CHART_SET,
} from '../../actionTypes';

export default (id) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_FETCH_CHART });
    dispatch({ type: CLEAR_ADD_CHART_ARTISTS });
    dispatch({ type: CLEAR_ADD_CHART_SETS });
    dispatch({ type: CLEAR_REMOVE_CHART_ARTIST });
    dispatch({ type: CLEAR_REMOVE_CHART_SET });

    const response = await axios.get(`/charts/${id}/for-admin`, config);

    const {
      data: { message, data },
    } = response;
    dispatch({
      type: FETCH_CHART_SUCCESS,
      message,
      results: data,
    });
  } catch (err) {
    let error = {};
    if (err.response) {
      const {
        data: { status, message },
      } = err.response;
      error = { status, message };
    } else {
      error = {
        status: 500,
        message: err.message,
      };
    }
    dispatch({ type: FETCH_CHART_FAILED, error });
  }
};
