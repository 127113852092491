/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import AddForm from './AddForm';
import Loader from '../../Loader';
import Error from '../../Error';
import getBanksAction from '../../../redux/actions/payments/getBanks';

const Modal = ({ refetch, getBanksAction: fetchBanks, getBanks }) => {
  const [status, setStatus] = useState('initial');
  const [banksData, setBanksData] = useState([]);

  useEffect(() => {
    if (status === 'initial') {
      fetchBanks();
      setStatus('fetching');
    }

    if (getBanks.status === 'error') {
      return setStatus('unknown_error');
    }

    if (getBanks.status === 'success') {
      setBanksData(getBanks.results);
      return setStatus('success');
    }

    return undefined;
  }, [getBanks]);

  const closeModal = () => {
    $('#addProviderModal').modal('hide');
  };

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      case 'no_data':
        data = (
          <Error title="No data found!" description="No Provider added yet!" />
        );
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  return (
    <>
      <div
        className="modal fade"
        id="addProviderModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addProviderModal"
        aria-hidden="true"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ width: '100%' }}
          >
            <div
              className="modal-content bg-secondary text-white border-0 artist-modal"
              style={{ margin: 0 }}
            >
              <div className="modal-header">
                <h6
                  className="modal-title mx-auto text-center"
                  id="addProviderModal"
                >
                  Add Playlist
                </h6>
                <span
                  onClick={closeModal}
                  className="close-button cursor-pointer text-center"
                >
                  &times;
                </span>
              </div>
              <div className="px-3 pt-0 modal-body">
                <DisplayData>
                  <AddForm refetch={refetch} banksData={banksData} />
                </DisplayData>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  refetch: PropTypes.func.isRequired,
  getBanksAction: PropTypes.func.isRequired,
  getBanks: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ getBanks }) => ({ getBanks });

export default connect(mapStateToProps, { getBanksAction })(Modal);
