import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import logInAction from '../redux/actions/admin/login';
import validator from '../helpers/validator';

const LogInPage = ({ logInAction: logIn, admin }) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('INGOMA_ADMIN_TOKEN')) {
      return history.push('/');
    }
    setSubmitting(false);
    if (admin.status === 'login_error') {
      setSubmitting(false);
      return setErrors([admin.error.message]);
    }
    if (admin.status === 'login_success') {
      localStorage.setItem('INGOMA_ADMIN_TOKEN', admin.token);
      return window.location.assign('/');
    }
    return undefined;
  }, [admin]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      adminEmail: email,
      password,
    };

    const validationErrors = await validator({ email });
    if (validationErrors.length > 0) return setErrors(validationErrors);

    setErrors([]);
    setSubmitting(true);
    return logIn(data);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row sign-container justify-content-end">
          <div
            className="col-lg-4 py-5 text-white"
            style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
          >
            <div className="row">
              <div className="col">
                <div className="text-center">
                  <h3>Ingoma</h3>
                  <p>Log In</p>
                </div>
                <form className="px-5 pt-5">
                  {errors.map((error, index) => (
                    <div
                      className="alert alert-danger"
                      role="alert"
                      key={index}
                    >
                      {error}
                      <button
                        type="button"
                        className="close"
                        onClick={() => setErrors([])}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ))}
                  <div className="form-group py-1">
                    <div className="form-group">
                      <input
                        type="email"
                        className={`form-control form-control-lg authInput ${
                          submitting ? 'cursor-not-allowed' : ''
                        }`}
                        placeholder="Email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={!!submitting}
                        value={email}
                      />
                    </div>
                  </div>

                  <div className="form-group py-1">
                    <div className="form-group">
                      <input
                        type="password"
                        className={`form-control form-control-lg authInput ${
                          submitting ? 'cursor-not-allowed' : ''
                        }`}
                        placeholder="Password"
                        required
                        disabled={!!submitting}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                    </div>
                  </div>
                  <div className="px-5 mt-5 text-center">
                    {submitting ? (
                      <button
                        type="submit"
                        className="btn btn-secondary btn-block login-button"
                        disabled
                      >
                        LOGGING IN
                        <span
                          className="spinner-border spinner-border ml-2"
                          style={{
                            width: '1.5rem',
                            height: '1.5rem',
                            fontSize: '0.7rem',
                          }}
                          role="status"
                          aria-hidden="true"
                        />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        onClick={handleSubmit}
                      >
                        LOG IN
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

LogInPage.propTypes = {
  logInAction: PropTypes.func.isRequired,
  admin: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ admin }) => ({ admin });

export default connect(mapStateToProps, { logInAction })(LogInPage);
