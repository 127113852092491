import axios, { config } from '..';
import {
  FETCH_PLAYLISTS_SUCCESS,
  FETCH_PLAYLISTS_FAILED,
  CLEAR_ADD_PLAYLIST,
  CLEAR_FETCH_PLAYLISTS,
  CLEAR_FETCH_PLAYLIST,
  CLEAR_UPDATE_PLAYLIST,
  CLEAR_ADD_PLAYLIST_TO_FEED,
  CLEAR_REMOVE_PLAYLIST_TO_FEED,
} from '../../actionTypes';

export default () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ADD_PLAYLIST });
    dispatch({ type: CLEAR_FETCH_PLAYLISTS });
    dispatch({ type: CLEAR_FETCH_PLAYLIST });
    dispatch({ type: CLEAR_UPDATE_PLAYLIST });
    dispatch({ type: CLEAR_REMOVE_PLAYLIST_TO_FEED });
    dispatch({ type: CLEAR_ADD_PLAYLIST_TO_FEED });

    const response = await axios.get('/feed-playlists/all/for-admin', config);

    const {
      data: { message, data },
    } = response;
    dispatch({
      type: FETCH_PLAYLISTS_SUCCESS,
      message,
      results: data,
    });
  } catch (err) {
    let error = {};
    if (err.response) {
      const {
        data: { status, message },
      } = err.response;
      error = { status, message };
    } else {
      error = {
        status: 500,
        message: err.message,
      };
    }
    dispatch({ type: FETCH_PLAYLISTS_FAILED, error });
  }
};
