/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { WithContext as ReactTags } from 'react-tag-input';

const TagInput = ({ tags, setTags, suggestions, placeholder }) => {
  const handleAddition = (tag) => setTags([...tags, tag]);
  const handleDelete = (i) => setTags(tags.filter((tag, index) => index !== i));
  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    return setTags(newTags);
  };

  return (
    <div>
      <ReactTags
        inputFieldPosition="bottom"
        classNames={{
          tags: 'tagsClass',
          tagInput: 'tagInputClass',
          tagInputField: 'tagInputFieldClass',
          selected: 'selectedClass',
          tag: 'tagClass',
          remove: 'removeTagClass',
          suggestions: 'suggestionsClass',
        }}
        tags={tags}
        placeholder={placeholder}
        suggestions={suggestions}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
      />
    </div>
  );
};

TagInput.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTags: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default TagInput;
