/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import updateFeedAction from '../../redux/actions/feed/updateFeed';

const Modal = ({
  id,
  updateFeedAction: updateAction,
  updateFeed,
  refetch,
  data,
}) => {
  const [title, setTitle] = useState(data.title);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setLoading(false);
    setErrors([]);

    if (updateFeed.status === 'success') {
      setLoading(false);
      $(`#updateFeedModal${id}`).modal('hide');
      setErrors([]);
      refetch();
    }
    if (updateFeed.status === 'error') {
      setLoading(false);
      return setErrors([updateFeed.error.message]);
    }

    return undefined;
  }, [updateFeed]);

  const handleSubmit = () => {
    let payload;
    if (!title) {
      return setErrors(["Title can't be empty!"]);
    }

    if (title !== data.title) {
      payload = { title };
    }

    if (payload) {
      updateAction(id, payload);
    } else {
      return setErrors(['Change something to update!']);
    }

    setErrors([]);
    return setLoading(true);
  };

  const closeModal = () => {
    $(`#updateFeedModal${id}`).modal('hide');
  };

  return (
    <>
      <button
        className="btn btn-success px-3 py-1 text-dark"
        type="button"
        data-toggle="modal"
        data-target={`#updateFeedModal${id}`}
      >
        <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
      </button>
      <div
        className="modal fade"
        id={`updateFeedModal${id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`updateFeedModal${id}`}
        aria-hidden="true"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ width: '100%' }}
          >
            <div
              className="modal-content bg-secondary text-white border-0 artist-modal"
              style={{ margin: 0 }}
            >
              <div className="modal-header">
                <h6
                  className="modal-title mx-auto text-center"
                  id={`updateFeedModal${id}`}
                >
                  Update Feed&#39;s Info.
                </h6>
                <span
                  onClick={closeModal}
                  className="close-button cursor-pointer text-center"
                >
                  &times;
                </span>
              </div>
              <div className="px-3 pt-0 modal-body">
                {errors.map((error, index) => (
                  <div
                    className="alert alert-danger text-center my-2"
                    role="alert"
                    key={index}
                  >
                    {error}
                    <button
                      type="button"
                      className="close"
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control authInput"
                      placeholder="Enter feed title"
                      style={{ fontSize: 15 }}
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                      required
                    />
                  </div>
                </form>
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-primary px-3 py-1 ml-2 mt-3"
                    onClick={handleSubmit}
                    disabled={!!loading}
                  >
                    Update
                    {loading ? (
                      <span className="spinner-border spinner-border-sm text-dark ml-2" />
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  refetch: PropTypes.func.isRequired,
  updateFeedAction: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  updateFeed: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ updateFeed }) => ({ updateFeed });

export default connect(mapStateToProps, { updateFeedAction })(Modal);
