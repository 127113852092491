import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Provider } from 'react-redux';
import store from '../redux/store';
import Login from './Login';
import Overview from './Overview';
import Providers from './Providers';
import Payouts from './Payouts';
import NotFound from './NotFound';
import AllFeeds from './feed/AllFeeds';
import AllPlaylists from './playlists/AllPlaylists';
import SinglePlaylist from './playlists/SinglePlaylist';
import AllCharts from './charts/AllCharts';
import SingleChart from './charts/SingleChart';

library.add(fab, fas);

export default function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Overview} />
          <Route exact path="/providers" component={Providers} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/payouts" component={Payouts} />

          {/* FEEDS ROUTES */}
          <Route exact path="/feed" component={AllFeeds} />
          <Route exact path="/playlists" component={AllPlaylists} />
          <Route
            exact
            path="/playlists/view/:playlistId"
            component={SinglePlaylist}
          />
          <Route exact path="/charts" component={AllCharts} />
          <Route
            exact
            path="/charts/view/:playlistId"
            component={SingleChart}
          />
          <Route exact path="/*" component={NotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}
