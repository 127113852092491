/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import updatePlaylistAction from '../../../redux/actions/playlists/updatePlaylist';

const Modal = ({
  updatePlaylistAction: updateAction,
  updatePlaylist,
  refetch,
  id,
  updateData,
}) => {
  const [title, setTitle] = useState(updateData.title);
  const [description, setDescription] = useState(updateData.description);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setLoading(false);
    setErrors([]);

    if (updatePlaylist.status === 'success') {
      setLoading(false);
      $(`#updatePlaylistModal${id}`).modal('hide');
      setErrors([]);
      refetch();
    }
    if (updatePlaylist.status === 'error') {
      setLoading(false);
      return setErrors([updatePlaylist.error.message]);
    }

    return undefined;
  }, [updatePlaylist]);

  const handleSubmit = () => {
    let payload;

    if (!title || !description) {
      return setErrors(['All fields are required!']);
    }

    if (title !== updateData.title || description !== updateData.description) {
      payload = { title, description };
    }

    if (payload) {
      updateAction(id, payload);
    } else {
      return setErrors(['Change something to update!']);
    }

    setErrors([]);
    return setLoading(true);
  };

  return (
    <div>
      {errors.map((error, index) => (
        <div
          className="alert alert-danger text-center my-2"
          role="alert"
          key={index}
        >
          {error}
          <button type="button" className="close" onClick={() => setErrors([])}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ))}
      <form>
        <div className="form-group">
          <input
            type="text"
            className="form-control authInput"
            placeholder="Enter playlist title"
            style={{ fontSize: 15 }}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            required
          />
        </div>
        <div className="form-group">
          <textarea
            className="form-control authInput"
            placeholder="Enter description"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            required
          />
        </div>
      </form>
      <div className="text-center">
        <button
          type="button"
          className="btn btn-primary px-3 py-1 ml-2 mt-3"
          onClick={handleSubmit}
          disabled={!!loading}
        >
          Update
          {loading ? (
            <span className="spinner-border spinner-border-sm text-dark ml-2" />
          ) : null}
        </button>
      </div>
    </div>
  );
};

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  updatePlaylistAction: PropTypes.func.isRequired,
  updatePlaylist: PropTypes.objectOf(PropTypes.any).isRequired,
  updateData: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ updatePlaylist }) => ({ updatePlaylist });

export default connect(mapStateToProps, { updatePlaylistAction })(Modal);
