import axios, { config } from '..';
import {
  FETCH_PLAYLIST_SUCCESS,
  FETCH_PLAYLIST_FAILED,
  CLEAR_FETCH_PLAYLIST,
  CLEAR_ADD_TRACKS,
  CLEAR_REMOVE_TRACK,
} from '../../actionTypes';

export default (id) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_FETCH_PLAYLIST });
    dispatch({ type: CLEAR_ADD_TRACKS });
    dispatch({ type: CLEAR_REMOVE_TRACK });

    const response = await axios.get(`/feed-playlists/${id}/for-admin`, config);

    const {
      data: { message, data },
    } = response;
    dispatch({
      type: FETCH_PLAYLIST_SUCCESS,
      message,
      results: data,
    });
  } catch (err) {
    let error = {};
    if (err.response) {
      const {
        data: { status, message },
      } = err.response;
      error = { status, message };
    } else {
      error = {
        status: 500,
        message: err.message,
      };
    }
    dispatch({ type: FETCH_PLAYLIST_FAILED, error });
  }
};
