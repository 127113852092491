/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import { getAllFeeds } from '../../../redux/actions/feed/fetchAllFeeds';
import AddForm from './ToFeedForm';

const Modal = ({ playlistId, refetch }) => {
  const [status, setStatus] = useState('initial');
  const [resultsData, setResultsData] = useState([]);

  const getFeeds = async () => {
    const allFeeds = await getAllFeeds();
    setResultsData(allFeeds);
    setStatus('');
  };

  useEffect(() => {
    if (status === 'initial') getFeeds();

    return undefined;
  });

  const closeModal = () => {
    $(`#chartToFeedModal${playlistId}`).modal('hide');
  };

  return (
    <>
      <button
        className="btn btn-warning px-3 py-1 text-dark"
        type="button"
        data-toggle="modal"
        data-target={`#chartToFeedModal${playlistId}`}
        title={
          resultsData.length === 0 || resultsData.length === undefined
            ? 'Add atleast one feed!'
            : 'Add to feed'
        }
        disabled={resultsData.length === 0 || resultsData.length === undefined}
      >
        <FontAwesomeIcon icon={['fas', 'plus-circle']} />
      </button>
      <div
        className="modal fade"
        id={`chartToFeedModal${playlistId}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`chartToFeedModal${playlistId}`}
        aria-hidden="true"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ width: '100%' }}
          >
            <div
              className="modal-content bg-secondary text-white border-0 artist-modal"
              style={{ margin: 0 }}
            >
              <div className="modal-header">
                <h6
                  className="modal-title mx-auto text-center"
                  id="chartToFeedModal"
                >
                  Add To Feed
                </h6>
                <span
                  onClick={closeModal}
                  className="close-button cursor-pointer text-center"
                >
                  &times;
                </span>
              </div>
              <div className="px-3 pt-0 modal-body">
                <AddForm
                  feedsData={resultsData}
                  refetch={refetch}
                  playlistId={playlistId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  playlistId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default Modal;
