/* eslint-disable operator-linebreak */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Breadcrumb from './components/BreadCrumb';

const withSidebar = (Component, active) => (props) => {
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem('INGOMA_ADMIN_TOKEN')) {
      return history.push('/login');
    }
    return undefined;
  }, []);

  return (
    <div className="row mx-0">
      <div className="col-sm-2 px-0">
        <Sidebar active={active} />
      </div>
      <div className="container col-sm-10 px-4 pt-3 pb-5">
        <Breadcrumb path={active} />
        <Component {...props} />
      </div>
    </div>
  );
};

export default withSidebar;
