import addPlaylist from './addPlaylist';
import fetchPlaylist from './fetchPlaylist';
import fetchPlaylists from './fetchPlaylists';
import updatePlaylist from './updatePlaylist';
import addToFeed from './addToFeed';
import removeFromFeed from './removeFromFeed';

export default {
  addPlaylist,
  fetchPlaylist,
  fetchPlaylists,
  updatePlaylist,
  addToFeed,
  removeFromFeed,
};
