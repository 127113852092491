/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UpdateChart from '../modals/charts/UpdateChart';
import AddChartToFeed from '../modals/charts/AddChartToFeed';
import RemoveChartFromFeed from '../modals/charts/RemoveChartFromFeed';

const Table = ({ data = [], refetch }) => (
  <>
    <div className="border rounded">
      <table className="table text-white text-center mb-0">
        <thead>
          <tr>
            <th scope="col">NO.</th>
            <th scope="col">Chart Title</th>
            <th scope="col">Type</th>
            <th scope="col">Edit</th>
            <th scope="col">Add to Feed</th>
            <th scope="col">Remove from Feed</th>
            <th scope="col">View</th>
          </tr>
        </thead>
        <tbody>
          {data.map((elt, index) => (
            <tr key={elt._id}>
              <td>{index + 1}</td>
              <td>{elt.title}</td>
              <td>{elt.type}</td>
              <td>
                <UpdateChart refetch={refetch} id={elt._id} data={elt} />
              </td>
              <td>
                <AddChartToFeed refetch={refetch} playlistId={elt._id} />
              </td>
              <td>
                <RemoveChartFromFeed refetch={refetch} playlistId={elt._id} />
              </td>
              <td>
                <Link
                  className="btn btn-info px-3 py-2"
                  to={`/charts/view/${elt._id}`}
                >
                  Details
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
);

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any]))
  ).isRequired,
  refetch: PropTypes.func.isRequired,
};

export default Table;
