/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withSidebar from '../../withSidebar';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import Table from '../../components/Tables/ChartArtists';
import Table2 from '../../components/Tables/SetsTable';
import fetchChartAction from '../../redux/actions/charts/fetchChart';
import AddModal from '../../components/modals/charts/artists/AddArtists';
import AddSets from '../../components/modals/charts/sets/AddSets';

const Playlist = ({ fetchChartAction: fetchAction, fetchChart, match }) => {
  const history = useHistory();
  const [status, setStatus] = useState('initial');
  const [resultsData, setResultsData] = useState({});
  const {
    params: { playlistId },
  } = match;

  useEffect(() => {
    if (!localStorage.getItem('INGOMA_ADMIN_TOKEN')) return history.push('/');

    if (status === 'initial') {
      fetchAction(playlistId);
      setStatus('fetching');
    }

    if (fetchChart.status === 'clear') {
      setStatus('fetching');
      setResultsData([]);
    }

    if (fetchChart.status === 'success') {
      setStatus('success');
      setResultsData(fetchChart.results);
    }

    if (fetchChart.status === 'error') {
      const { error } = fetchChart;
      if (error.status === 500) setStatus('unknown_error');
      if (error.status === 404) setStatus('no_data');
    }
    return undefined;
  }, [fetchChart]);

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      case 'no_data':
        data = (
          <Error
            title="No data found!"
            description="The chart doesn't exist!"
          />
        );
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  const refetch = () => {
    setStatus('fetching');
    return fetchAction(playlistId);
  };

  const modalBtn = {
    class: () => {
      if (status === 'success') {
        if (resultsData.type === 'artist') {
          const cname = 'btn-primary text-dark';
          return cname;
        }
        if (resultsData.type === 'set') {
          const cname = 'btn-primary text-dark';
          return cname;
        }
      }
      const cname = 'btn-primary text-dark';
      return cname;
    },
    modalId: () => {
      if (status === 'success') {
        if (resultsData.type === 'artist') {
          const cname = '#addArtistsModal';
          return cname;
        }
        if (resultsData.type === 'set') {
          const cname = '#addSetsModal';
          return cname;
        }
      }
      return '#';
    },
    btnText: () => {
      if (status === 'success') {
        if (resultsData.type === 'artist') {
          const cname = 'Add Artists +';
          return cname;
        }
        if (resultsData.type === 'set') {
          const cname = 'Add Sets +';
          return cname;
        }
      }
      return 'Add New +';
    },
  };

  const chartData = {
    length: () => {
      if (status === 'success') {
        if (resultsData.type === 'artist') {
          const cname = `${resultsData.artists.length} Artists`;
          return cname;
        }
        if (resultsData.type === 'set') {
          const cname = `${resultsData.sets.length} Sets`;
          return cname;
        }
      }
      const cname = '-';
      return cname;
    },
  };

  const chartTable = {
    table: () => {
      if (status === 'success') {
        if (resultsData.type === 'artist') {
          if (resultsData.artists.length === 0) {
            return (
              <Error
                title="No data found!"
                description="No artists added to this chart!"
              />
            );
          }
          return (
            <Table
              data={resultsData.artists}
              refetch={refetch}
              playlistId={playlistId}
              type={resultsData.type}
            />
          );
        }
        if (resultsData.type === 'set') {
          if (resultsData.sets.length === 0) {
            return (
              <Error
                title="No data found!"
                description="No sets added to this chart!"
              />
            );
          }
          return (
            <Table2
              data={resultsData.sets}
              refetch={refetch}
              playlistId={playlistId}
              type={resultsData.type}
            />
          );
        }
      }

      return <></>;
    },
  };

  return (
    <>
      <div className="py-3">
        <div className="row py-2 mt-4 mb-3">
          <div className="col-4">
            <p className="text-primary" style={{ fontSize: 16 }}>
              <FontAwesomeIcon icon={['fas', 'text-width']} className="mr-2" />
              Title:
              <span className="ml-2" style={{ fontSize: 14, color: '#fff' }}>
                {status === 'success' ? resultsData.title : '-'}
              </span>
            </p>
            <p className="text-primary" style={{ fontSize: 16 }}>
              <FontAwesomeIcon icon={['fas', 'list-alt']} className="mr-2" />
              Type:
              <span className="ml-2" style={{ fontSize: 14, color: '#fff' }}>
                {status === 'success'
                  ? resultsData.type.charAt(0).toUpperCase() +
                    resultsData.type.slice(1)
                  : '-'}
              </span>
            </p>
            <p className="text-primary" style={{ fontSize: 16 }}>
              <FontAwesomeIcon icon={['fas', 'play-circle']} className="mr-2" />
              Data:
              <span className="ml-2 text-white" style={{ fontSize: 14 }}>
                {chartData.length()}
              </span>
            </p>
            <button
              className={`btn ${modalBtn.class()} px-3 py-1`}
              type="button"
              data-toggle="modal"
              data-target={modalBtn.modalId()}
              disabled={status !== 'success'}
            >
              {modalBtn.btnText()}
            </button>
            {status === 'success' ? (
              resultsData.type === 'artist' ? (
                <AddModal refetch={refetch} playlistId={playlistId} />
              ) : (
                <AddSets refetch={refetch} playlistId={playlistId} />
              )
            ) : (
              <></>
            )}
          </div>
        </div>
        <DisplayData>{chartTable.table()}</DisplayData>
      </div>
    </>
  );
};

Playlist.propTypes = {
  fetchChartAction: PropTypes.func.isRequired,
  fetchChart: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ fetchChart }) => ({ fetchChart });

export default connect(mapStateToProps, { fetchChartAction })(
  withSidebar(Playlist, 'charts')
);
