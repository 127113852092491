/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import addPlaylistAction from '../../../redux/actions/playlists/addPlaylist';

const Modal = ({
  addPlaylistAction: addAction,
  addPlaylist,
  refetch,
  setErrors,
  artistsData = [],
}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [criteria, setCriteria] = useState('artist');
  const [loading, setLoading] = useState(true);
  const [genre, setGenre] = useState(0);
  const [artist, setArtist] = useState(0);

  useEffect(() => {
    setLoading(false);

    if (addPlaylist.status === 'success') {
      setLoading(false);
      $('#addPlaylistModal').modal('hide');
      setTitle('');
      setDescription('');
      setCriteria('artist');
      setGenre(0);
      setArtist(0);
      setErrors([]);
      refetch();
    }
    if (addPlaylist.status === 'error') {
      setLoading(false);
      return setErrors([addPlaylist.error.message]);
    }

    return undefined;
  }, [addPlaylist]);

  const handleSubmit = () => {
    const data = {
      title,
      description,
      criteria,
      baseOn: criteria === 'artist' ? artist : genre,
    };
    if (
      !title ||
      !description ||
      !criteria ||
      (criteria === 'artist' && !artist) ||
      criteria === 'top' ||
      (criteria === 'new' && !genre)
    ) {
      return setErrors(['All fields are required!']);
    }
    setErrors([]);
    setLoading(true);
    return addAction('generated', data);
  };

  return (
    <div className="mt-3">
      <form>
        <div className="form-group">
          <input
            type="text"
            className="form-control authInput"
            placeholder="Enter playlist title"
            style={{ fontSize: 15 }}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            required
          />
        </div>
        <div className="form-group">
          <textarea
            className="form-control authInput"
            placeholder="Enter description"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            required
          />
        </div>
        <div className="form-group">
          <select
            className="custom-select form-control authInput cursor-pointer"
            onChange={(e) => setCriteria(e.target.value)}
            value={criteria}
          >
            <option value={0} defaultValue disabled>
              Choose criteria
            </option>
            <option value="artist">Artist</option>
            <option value="new">New</option>
            <option value="top">Top</option>
          </select>
        </div>
        <div className="form-group">
          {criteria === 'artist' ? (
            <select
              value={artist}
              onChange={(e) => setArtist(e.target.value)}
              className="custom-select form-control authInput cursor-pointer"
            >
              <option disabled defaultValue value={0}>
                Choose Artist
              </option>
              {artistsData.map((elt) => (
                <option value={elt.id} key={elt.id}>
                  {elt.text}
                </option>
              ))}
            </select>
          ) : (
            <select
              value={genre}
              onChange={(e) => setGenre(e.target.value)}
              className="custom-select form-control authInput cursor-pointer"
            >
              <option disabled defaultValue value={0}>
                Genre
              </option>
              <option value="R&B">R&B</option>
              <option value="Kinyatrap">Kinyatrap</option>
              <option value="Hip Hop">Hip Hop</option>
              <option value="Pop">Pop</option>
              <option value="Pop">Reggae</option>
              <option value="Afrobeat">Afrobeat</option>
              <option value="Gospel">Gospel</option>
              <option value="Karahanyuze">Karahanyuze</option>
              <option value="Dance">Dance</option>
            </select>
          )}
        </div>
      </form>
      <div className="text-center">
        <button
          type="button"
          className="btn btn-primary px-3 py-1 ml-2 mt-3"
          onClick={handleSubmit}
          disabled={!!loading}
        >
          Add
          {loading ? (
            <span className="spinner-border spinner-border-sm text-dark ml-2" />
          ) : null}
        </button>
      </div>
    </div>
  );
};

Modal.propTypes = {
  setErrors: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  addPlaylistAction: PropTypes.func.isRequired,
  addPlaylist: PropTypes.objectOf(PropTypes.any).isRequired,
  artistsData: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any]))
  ).isRequired,
};

const mapStateToProps = ({ addPlaylist }) => ({ addPlaylist });

export default connect(mapStateToProps, { addPlaylistAction })(Modal);
