/* eslint-disable no-unused-expressions */
/* eslint-disable radix */
export const monthToLetter = () => {
  const months = [
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  return months;
};

export default (startDate, endDate) => {
  const startYear = parseInt(startDate);
  const endYear = parseInt(endDate);
  const dates = [];

  for (let i = startYear; i <= endYear; i += 1) {
    dates.push(i);
  }
  return dates;
};
