/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

const Table = ({ data = [] }) => (
  <>
    <div className="border rounded mt-5">
      <table className="table text-white text-center mb-0">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Type</th>
            <th scope="col">Email</th>
          </tr>
        </thead>
        <tbody>
          {data.map((elt) => (
            <tr key={elt._id}>
              <td>{elt.name}</td>
              <td>{elt.type}</td>
              <td>{elt.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
);

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any]))
  ).isRequired,
};

export default Table;
