/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import AddForm from './AddForm';
import AddForm2 from './AddForm2';
import fetchArtists from '../../../redux/actions/artists/getPlatformArtists';

const Modal = ({ refetch }) => {
  const [type, setType] = useState('manual');
  const [status, setStatus] = useState('initial');
  const [resultsData, setResultsData] = useState([]);
  const [errors, setErrors] = useState([]);

  const getArtists = async () => {
    const platformArtists = await fetchArtists();
    setResultsData(platformArtists);
    setStatus('');
  };

  useEffect(() => {
    if (status === 'initial') getArtists();

    return undefined;
  });

  const closeModal = () => {
    $('#addPlaylistModal').modal('hide');
  };

  return (
    <>
      <div
        className="modal fade"
        id="addPlaylistModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addPlaylistModal"
        aria-hidden="true"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ width: '100%' }}
          >
            <div
              className="modal-content bg-secondary text-white border-0 artist-modal"
              style={{ margin: 0 }}
            >
              <div className="modal-header">
                <h6
                  className="modal-title mx-auto text-center"
                  id="addPlaylistModal"
                >
                  Add Playlist
                </h6>
                <span
                  onClick={closeModal}
                  className="close-button cursor-pointer text-center"
                >
                  &times;
                </span>
              </div>
              <div className="px-3 pt-0 modal-body">
                {errors.map((error, index) => (
                  <div
                    className="alert alert-danger text-center my-2"
                    role="alert"
                    key={index}
                  >
                    {error}
                    <button
                      type="button"
                      className="close"
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
                <select
                  className="custom-select form-control authInput cursor-pointer"
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                >
                  <option value={0} defaultValue disabled>
                    Choose Type
                  </option>
                  <option value="manual">Manual</option>
                  <option value="generated">Generated</option>
                </select>
                {type === 'manual' ? (
                  <AddForm refetch={refetch} setErrors={setErrors} />
                ) : (
                  <AddForm2
                    refetch={refetch}
                    artistsData={resultsData}
                    setErrors={setErrors}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default Modal;
