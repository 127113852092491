/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Placeholder from '../../assets/images/placeHolder.png';

const Details = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const imgSrc = () => {
    if (loading) {
      return Placeholder;
    }
    if (data.nationalIdCard) {
      return data.nationalIdCard;
    }
    return Placeholder;
  };
  return (
    <>
      <div className="row mx-auto data-div mt-1">
        <div className="col-12">
          <h5
            className="text-left mt-3 mb-3"
            style={{ fontSize: 16, color: '#ccc' }}
          >
            <FontAwesomeIcon
              icon={['fas', 'id-badge']}
              className="auth-form-icon mr-2"
            />
            Identification
          </h5>
        </div>

        <div className="col-12 text-center">
          <div className="info-div">
            <img
              style={{
                objectFit: 'cover',
                width: '380px',
                height: '250px',
              }}
              className="rounded"
              src={imgSrc()}
              alt="ID Card Pic"
              srcSet=""
              onLoad={() => setLoading(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

Details.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Details;
