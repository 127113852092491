/* eslint-disable react/prop-types */
import React from 'react';

export default {
  overview: ({ width, height, color }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 3.75H17.5V26.25H12.5V3.75Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 9.99988H27.5V26.2499H22.5V9.99988Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 16.2501H7.5V26.2501H2.5V16.2501Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  music: ({ width, height, color }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="14" stroke={color} strokeWidth="2" />
      <circle cx="15" cy="15.0001" r="4" stroke={color} strokeWidth="2" />
    </svg>
  ),
  payouts: ({ width, height, color }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 7C1.25 5.89543 2.14543 5 3.25 5H26.75C27.8546 5 28.75 5.89543 28.75 7V23C28.75 24.1046 27.8546 25 26.75 25H3.25C2.14543 25 1.25 24.1046 1.25 23V7Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 12.5H28.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  settings: ({ width, height, color }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 18.75C17.0711 18.75 18.75 17.0711 18.75 15C18.75 12.9289 17.0711 11.25 15 11.25C12.9289 11.25 11.25 12.9289 11.25 15C11.25 17.0711 12.9289 18.75 15 18.75Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.25 18.75C23.9096 19.5214 24.0729 20.4223 24.6625 21.025L24.7375 21.1C25.2069 21.5689 25.4707 22.2052 25.4707 22.8688C25.4707 23.5323 25.2069 24.1686 24.7375 24.6375C24.2686 25.1069 23.6323 25.3707 22.9688 25.3707C22.3052 25.3707 21.6689 25.1069 21.2 24.6375L21.125 24.5625C20.5223 23.9729 19.6214 23.8096 18.85 24.15C18.0944 24.4738 17.6033 25.2154 17.6 26.0375V26.25C17.6 27.6307 16.4807 28.75 15.1 28.75C13.7193 28.75 12.6 27.6307 12.6 26.25V26.1375C12.5802 25.2908 12.0448 24.5423 11.25 24.25C10.4786 23.9096 9.57773 24.0729 8.975 24.6625L8.9 24.7375C8.43108 25.2069 7.79477 25.4707 7.13125 25.4707C6.46773 25.4707 5.83142 25.2069 5.3625 24.7375C4.89306 24.2686 4.62928 23.6323 4.62928 22.9688C4.62928 22.3052 4.89306 21.6689 5.3625 21.2L5.4375 21.125C6.02708 20.5223 6.19043 19.6214 5.85 18.85C5.52616 18.0944 4.78455 17.6033 3.9625 17.6H3.75C2.36929 17.6 1.25 16.4807 1.25 15.1C1.25 13.7193 2.36929 12.6 3.75 12.6H3.8625C4.70916 12.5802 5.45767 12.0448 5.75 11.25C6.09043 10.4786 5.92708 9.57773 5.3375 8.975L5.2625 8.9C4.79306 8.43108 4.52928 7.79477 4.52928 7.13125C4.52928 6.46773 4.79306 5.83142 5.2625 5.3625C5.73142 4.89306 6.36773 4.62928 7.03125 4.62928C7.69477 4.62928 8.33108 4.89306 8.8 5.3625L8.875 5.4375C9.47773 6.02708 10.3786 6.19043 11.15 5.85H11.25C12.0056 5.52616 12.4967 4.78455 12.5 3.9625V3.75C12.5 2.36929 13.6193 1.25 15 1.25C16.3807 1.25 17.5 2.36929 17.5 3.75V3.8625C17.5033 4.68455 17.9944 5.42616 18.75 5.75C19.5214 6.09043 20.4223 5.92708 21.025 5.3375L21.1 5.2625C21.5689 4.79306 22.2052 4.52928 22.8688 4.52928C23.5323 4.52928 24.1686 4.79306 24.6375 5.2625C25.1069 5.73142 25.3707 6.36773 25.3707 7.03125C25.3707 7.69477 25.1069 8.33108 24.6375 8.8L24.5625 8.875C23.9729 9.47773 23.8096 10.3786 24.15 11.15V11.25C24.4738 12.0056 25.2154 12.4967 26.0375 12.5H26.25C27.6307 12.5 28.75 13.6193 28.75 15C28.75 16.3807 27.6307 17.5 26.25 17.5H26.1375C25.3154 17.5033 24.5738 17.9944 24.25 18.75Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  logout: ({ width, height, color }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3333 2H19.7778C20.3671 2 20.9324 2.23413 21.3491 2.65087C21.7659 3.06762 22 3.63285 22 4.22222L22 19.7778C22 20.3671 21.7659 20.9324 21.3491 21.3491C20.9324 21.7659 20.3671 22 19.7778 22H15.3333"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.55554 6.44443L1.99999 12L7.55554 17.5555"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.00004 12L15.3334 12"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  refresh: ({ width, height, color }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4V10H8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.51 15C5.84201 18.7808 9.51384 21.2248 13.5157 20.9945C17.5176 20.7642 20.8848 17.915 21.7742 14.0064C22.6637 10.0978 20.861 6.07214 17.3528 4.1328C13.8447 2.19346 9.47683 2.808 6.64 5.64004L2 10"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  success: ({ width, height, color }) => (
    <svg
      width={width}
      height={height}
      className="check-success"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 12.07V13a10 10 0 11-5.93-9.14"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 4L11 15l-3-3"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};
