/* eslint-disable object-curly-newline */
/* eslint-disable array-callback-return */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import RemoveTrack from '../modals/tracks/RemoveTrack';

const Table = ({ data = [], refetch, playlistId, type }) => {
  const zerofy = (n) => (n.toString().length >= 2 ? n : `${0}${n}`);

  const minutesAndSeconds = (position) =>
    `${zerofy(Math.floor(position / 60))}:${zerofy(Math.floor(position % 60))}`;

  return (
    <>
      <div className="border rounded">
        <table className="table text-white text-center mb-0">
          <thead>
            <tr>
              <th scope="col">NO.</th>
              <th scope="col">Song Title</th>
              <th scope="col">Album</th>
              <th scope="col">Artists</th>
              <th scope="col">Duration</th>
              {type === 'manual' ? <th scope="col">Remove</th> : null}
            </tr>
          </thead>
          <tbody>
            {data.map((elt, index) => {
              let artists = elt.artist.name;
              if (elt.featuring.length !== 0) {
                elt.featuring.map((feature) => {
                  artists = artists.concat(', ', feature.name);
                });
              }
              return (
                <tr key={elt._id}>
                  <td>{index + 1}</td>
                  <td>{elt.title}</td>
                  <td>{elt.musicSet.name}</td>
                  <td>{artists}</td>
                  <td>{minutesAndSeconds(elt.duration)}</td>
                  {type === 'manual' ? (
                    <th scope="col">
                      <td>
                        <RemoveTrack
                          data={elt}
                          id={elt._id}
                          playlistId={playlistId}
                          refetch={refetch}
                        />
                      </td>
                    </th>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any]))
  ).isRequired,
  refetch: PropTypes.func.isRequired,
  playlistId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Table;
