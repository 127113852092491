import axios, { config } from '..';
import { ADD_PLAYLIST_SUCCESS, ADD_PLAYLIST_FAILED } from '../../actionTypes';

export default (type, payload) => async (dispatch) => {
  try {
    let response;

    if (type === 'manual') {
      response = await axios.post(
        '/feed-playlists/manual/new',
        payload,
        config
      );
    } else {
      response = await axios.post(
        '/feed-playlists/generated/new',
        payload,
        config
      );
    }

    const {
      data: { message },
    } = response;
    dispatch({
      type: ADD_PLAYLIST_SUCCESS,
      message,
    });
  } catch (err) {
    let error = {};
    if (err.response) {
      const {
        data: { status, message },
      } = err.response;
      error = { status, message };
    } else {
      error = {
        status: 500,
        message: err.message,
      };
    }
    dispatch({ type: ADD_PLAYLIST_FAILED, error });
  }
};
