import addFeed from './addFeed';
import fetchFeed from './fetchFeed';
import fetchFeeds from './fetchFeeds';
import releaseFeed from './releaseFeed';
import updateFeed from './updateFeed';

export default {
  addFeed,
  fetchFeed,
  fetchFeeds,
  releaseFeed,
  updateFeed,
};
