import axios, { config } from '..';

export default async (active) => {
  try {
    const response = await axios.get('/music-sets/all', config);
    return response.data.data.reduce((result, { _id, name, type }) => {
      if (active !== _id) result.push({ id: _id, text: `${name} - ${type}` });
      return result;
    }, []);
  } catch (error) {
    return error;
  }
};
