/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withSidebar from '../withSidebar';
import Loader from '../components/Loader';
import Error from '../components/Error';
import Table from '../components/Tables/ArtistsTable';
import fetchArtistsAction from '../redux/actions/artists/fetchArtists';

const Overview = ({ fetchArtistsAction: fetchAction, getArtists }) => {
  const history = useHistory();
  const [status, setStatus] = useState('initial');
  const [verifStatus, setVerifStatus] = useState('pending');
  const [artistsData, setArtistsData] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem('INGOMA_ADMIN_TOKEN')) return history.push('/');
    if (status === 'initial') {
      fetchAction('pending');
      setStatus('fetching');
    }

    if (getArtists.status === 'clear') {
      setStatus('fetching');
      setArtistsData([]);
    }

    if (getArtists.status === 'success') {
      setStatus('success');
      setArtistsData(getArtists.results);
    }

    if (getArtists.status === 'error') {
      const { error } = getArtists;
      if (error.status === 500) setStatus('unknown_error');
      if (error.status === 404) setStatus('no_data');
    }
    return undefined;
  }, [getArtists]);

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      case 'no_data':
        data = (
          <Error
            title="No data found!"
            description={`No Artists with ${verifStatus} Status`}
          />
        );
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  const handleChange = (e) => {
    setVerifStatus(e.target.value);
    setArtistsData([]);
    setStatus('fetching');
    return fetchAction(e.target.value);
  };

  const refetch = () => {
    setStatus('fetching');
    return fetchAction(verifStatus);
  };

  return (
    <>
      <div className="py-3">
        <div className="row py-2">
          <div className="col-4">
            <form className="mt-4">
              <div className="form-row">
                <div className="form-group col-5">
                  <select
                    className={`custom-select bg-primary border-0 text-dark ${
                      status === 'fetching'
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }`}
                    onChange={handleChange}
                    value={verifStatus}
                    disabled={status === 'fetching'}
                  >
                    <option value={0} disabled>
                      Choose Status
                    </option>
                    <option value="verified">Verified</option>
                    <option value="pending">Pending</option>
                    <option value="denied">Denied</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>
        <DisplayData>
          <Table data={artistsData} refetch={refetch} />
        </DisplayData>
      </div>
    </>
  );
};

Overview.propTypes = {
  fetchArtistsAction: PropTypes.func.isRequired,
  getArtists: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ getArtists }) => ({ getArtists });

export default connect(mapStateToProps, { fetchArtistsAction })(
  withSidebar(Overview, 'artists')
);
