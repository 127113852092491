import { LOGIN_SUCCESS, LOGIN_FAILED, ADMIN_LOGOUT } from '../../actionTypes';

const initialState = {
  status: '',
  message: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        status: 'login_success',
        message: action.message,
        token: action.token,
      };
    case LOGIN_FAILED:
      return { ...state, status: 'login_error', error: action.error };
    case ADMIN_LOGOUT:
      return { ...state, status: 'logout_success' };
    default:
      return state;
  }
};
