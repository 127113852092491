/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UpdatePlaylist from '../modals/playlists/UpdatePlaylist';
import AddToFeed from '../modals/playlists/AddToFeed';
import RemoveFromFeed from '../modals/playlists/RemoveFromFeed';

const Table = ({ data = [], refetch }) => (
  <>
    <div className="border rounded">
      <table className="table text-white text-center mb-0">
        <thead>
          <tr>
            <th scope="col">NO.</th>
            <th scope="col">Playlist Title</th>
            <th scope="col">Type</th>
            <th scope="col">Tracks NO.</th>
            <th scope="col">Edit</th>
            <th scope="col">Add to Feed</th>
            <th scope="col">Remove from Feed</th>
            <th scope="col">View</th>
          </tr>
        </thead>
        <tbody>
          {data.map((elt, index) => (
            <tr key={elt._id}>
              <td>{index + 1}</td>
              <td>{elt.title}</td>
              <td>{elt.type}</td>
              <td>{`${elt.tracks.length} Tracks`}</td>
              <td>
                <UpdatePlaylist data={elt} id={elt._id} refetch={refetch} />
              </td>
              <td>
                <AddToFeed playlistId={elt._id} refetch={refetch} />
              </td>
              <td>
                <RemoveFromFeed playlistId={elt._id} refetch={refetch} />
              </td>
              <td>
                <Link
                  className="btn btn-info px-4 py-1"
                  to={`/playlists/view/${elt._id}`}
                >
                  Details
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
);

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any]))
  ).isRequired,
  refetch: PropTypes.func.isRequired,
};

export default Table;
