import axios, { config } from '..';
import {
  FETCH_ALL_FEED_SUCCESS,
  FETCH_ALL_FEED_FAILED,
  CLEAR_ADD_FEED,
  CLEAR_FETCH_ALL_FEED,
  CLEAR_UPDATE_FEED,
  CLEAR_RELEASE_FEED,
  CLEAR_FETCH_FEED,
} from '../../actionTypes';

export default () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ADD_FEED });
    dispatch({ type: CLEAR_FETCH_ALL_FEED });
    dispatch({ type: CLEAR_UPDATE_FEED });
    dispatch({ type: CLEAR_RELEASE_FEED });
    dispatch({ type: CLEAR_FETCH_FEED });

    const response = await axios.get('/feeds/all', config);

    const {
      data: { message, data },
    } = response;
    dispatch({
      type: FETCH_ALL_FEED_SUCCESS,
      message,
      results: data,
    });
  } catch (err) {
    let error = {};
    if (err.response) {
      const {
        data: { status, message },
      } = err.response;
      error = { status, message };
    } else {
      error = {
        status: 500,
        message: err.message,
      };
    }
    dispatch({ type: FETCH_ALL_FEED_FAILED, error });
  }
};

export const getAllFeeds = async () => {
  try {
    const response = await axios.get('/feeds/all', config);
    return response.data.data.reduce((result, { _id, title }) => {
      result.push({ id: _id, text: title });
      return result;
    }, []);
  } catch (error) {
    return error;
  }
};
