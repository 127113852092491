/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UpdateFeed from '../modals/UpdateFeed';
import ReleaseFeed from '../modals/ReleaseFeed';

const Table = ({ data = [], refetch }) => (
  <>
    <div className="border rounded">
      <table className="table text-white text-center mb-0">
        <thead>
          <tr>
            <th scope="col">NO.</th>
            <th scope="col">Feed Title</th>
            <th scope="col">Status</th>
            <th scope="col">Edit</th>
            <th scope="col">Release</th>
          </tr>
        </thead>
        <tbody>
          {data.map((elt, index) => (
            <tr key={elt._id}>
              <td>{index + 1}</td>
              <td>{elt.title}</td>
              <td>{elt.isLive ? 'Live' : 'Not Live'}</td>
              <td>
                <UpdateFeed refetch={refetch} id={elt._id} data={elt} />
              </td>
              <td>
                <button
                  className="btn btn-warning px-3 py-1 text-dark"
                  type="button"
                  data-toggle="modal"
                  data-target={`#releaseFeedModal${elt._id}`}
                  disabled={!!elt.isLive}
                >
                  <FontAwesomeIcon icon={['fas', 'check-circle']} />
                </button>
                <ReleaseFeed refetch={refetch} id={elt._id} data={elt} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
);

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any]))
  ).isRequired,
  refetch: PropTypes.func.isRequired,
};

export default Table;
