/* eslint-disable object-curly-newline */
/* eslint-disable array-callback-return */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RemoveArtists from '../modals/charts/artists/RemoveArtists';
import artistPlaceholder from '../../assets/images/placeHolder.png';

const Table = ({ data = [], refetch, playlistId }) => {
  const [loading, setLoading] = useState(true);
  const imgSrc = (value) => {
    if (loading) {
      return artistPlaceholder;
    }
    if (value) {
      return value;
    }
    return artistPlaceholder;
  };
  return (
    <>
      <div className="border rounded">
        <table className="table text-white text-center mb-0">
          <thead>
            <tr>
              <th scope="col">NO.</th>
              <th scope="col">Photo</th>
              <th scope="col">Artist Name</th>
              <th scope="col">Remove</th>
            </tr>
          </thead>
          <tbody>
            {data.map((elt, index) => (
              <tr key={elt._id}>
                <td>{index + 1}</td>
                <th label="image" scope="row">
                  <img
                    className="rounded-circle"
                    width="40px"
                    height="40px"
                    style={{ objectFit: 'cover' }}
                    src={imgSrc(elt.profilePhotoLink)}
                    alt="Artist Pic"
                    srcSet=""
                    onLoad={() => setLoading(false)}
                  />
                </th>
                <td>{elt.name}</td>
                <td>
                  <RemoveArtists
                    data={elt}
                    id={elt._id}
                    playlistId={playlistId}
                    refetch={refetch}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any]))
  ).isRequired,
  refetch: PropTypes.func.isRequired,
  playlistId: PropTypes.string.isRequired,
};

export default Table;
