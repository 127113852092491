import {
  DENY_ARTISTS_SUCCESS,
  DENY_ARTISTS_FAILED,
  CLEAR_DENY_ARTISTS,
} from '../../actionTypes';

const initialState = {
  status: '',
  message: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DENY_ARTISTS_SUCCESS:
      return {
        ...state,
        status: 'deny_success',
        message: action.message,
      };
    case DENY_ARTISTS_FAILED:
      return { ...state, status: 'deny_error', error: action.error };

    case CLEAR_DENY_ARTISTS:
      return { ...initialState, status: 'clear_deny' };
    default:
      return state;
  }
};
