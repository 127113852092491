/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import addToFeedAction from '../../../redux/actions/playlists/addToFeed';

const Modal = ({
  addToFeedAction: addAction,
  addToFeed,
  refetch,
  feedsData,
  playlistId,
}) => {
  const [feed, setFeed] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setLoading(false);

    if (addToFeed.status === 'success') {
      setLoading(false);
      $(`#addToFeedModal${playlistId}`).modal('hide');
      setErrors([]);
      refetch();
    }
    if (addToFeed.status === 'error') {
      setLoading(false);
      return setErrors([addToFeed.error.message]);
    }

    return undefined;
  }, [addToFeed]);

  const handleSubmit = () => {
    const data = { feed };
    if (!feed) {
      return setErrors(['Please select feed, to add playlist to!']);
    }
    setErrors([]);
    setLoading(true);
    return addAction(playlistId, data);
  };

  return (
    <div>
      {errors.map((error, index) => (
        <div
          className="alert alert-danger text-center my-2"
          role="alert"
          key={index}
        >
          {error}
          <button type="button" className="close" onClick={() => setErrors([])}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ))}
      <form>
        <div className="form-group">
          <select
            value={feed}
            onChange={(e) => setFeed(e.target.value)}
            className="custom-select form-control authInput cursor-pointer"
          >
            <option disabled defaultValue value={0}>
              Choose Feed
            </option>
            {feedsData.length
              ? feedsData.map((elt) => (
                  <option value={elt.id} key={elt.id}>
                    {elt.text}
                  </option>
                ))
              : null}
          </select>
        </div>
      </form>
      <div className="text-center">
        <button
          type="button"
          className="btn btn-primary px-3 py-1 ml-2 mt-3"
          onClick={handleSubmit}
          disabled={!!loading}
        >
          Add
          {loading ? (
            <span className="spinner-border spinner-border-sm text-dark ml-2" />
          ) : null}
        </button>
      </div>
    </div>
  );
};

Modal.propTypes = {
  playlistId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  addToFeedAction: PropTypes.func.isRequired,
  addToFeed: PropTypes.objectOf(PropTypes.any).isRequired,
  feedsData: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any]))
  ).isRequired,
};

const mapStateToProps = ({ addToFeed }) => ({ addToFeed });

export default connect(mapStateToProps, { addToFeedAction })(Modal);
