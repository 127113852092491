import axios, { config } from '..';

export default async () => {
  try {
    const response = await axios.get('/tracks/all', config);
    return response.data.data.reduce(
      (result, { _id, title, artist: { name } }) => {
        result.push({ id: _id, text: `${title} - ${name}` });
        return result;
      },
      []
    );
  } catch (error) {
    return error;
  }
};
