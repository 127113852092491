import axios, { config } from '..';
import {
  FETCH_ARTISTS_SUCCESS,
  FETCH_ARTISTS_FAILED,
  CLEAR_ARTISTS,
  CLEAR_VERIFY_ARTISTS,
  CLEAR_DENY_ARTISTS,
} from '../../actionTypes';

export default (verifStatus) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ARTISTS });
    dispatch({ type: CLEAR_VERIFY_ARTISTS });
    dispatch({ type: CLEAR_DENY_ARTISTS });

    const response = await axios.get(
      `/artists/verifications?status=${verifStatus}`,
      config
    );

    const {
      data: { message, data },
    } = response;
    dispatch({
      type: FETCH_ARTISTS_SUCCESS,
      message,
      results: data,
    });
  } catch (err) {
    let error = {};
    if (err.response) {
      const {
        data: { status, message },
      } = err.response;
      error = { status, message };
    } else {
      error = {
        status: 500,
        message: err.message,
      };
    }
    dispatch({ type: FETCH_ARTISTS_FAILED, error });
  }
};
