/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '../components/usage/Icon';
import Loader from '../components/Loader';
import Error from '../components/Error';
import Table from '../components/Tables/PayoutsTable';
import dateRange, { monthToLetter } from '../helpers/dateRange';
import withSidebar from '../withSidebar';
import fetchPayoutsAction from '../redux/actions/payments/fetchPayouts';
import payArtistsAction from '../redux/actions/payments/payArtists';
import NumberCard from '../components/NumberCard';

const Payouts = ({
  fetchPayoutsAction: fetchAction,
  fetchPayouts,
  payArtistsAction: payAction,

  payArtists,
}) => {
  const history = useHistory();
  const [selectData, setSelectData] = useState([]);
  const [status, setStatus] = useState('initial');
  const [error, setError] = useState('');
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);
  const [keepMonth, setKeepMonth] = useState('');
  const [keepYear, setKeepYear] = useState('');
  const [payoutsData, setPayoutsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('INGOMA_ADMIN_TOKEN')) return history.push('/');

    const currentDate = new Date();

    const range = dateRange(2021, currentDate.getFullYear());

    setSelectData(range);

    if (status === 'initial') {
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();

      setMonth(currentMonth);
      setYear(currentYear);
      fetchAction(currentMonth, currentYear);
      setStatus('fetching');
    }

    if (fetchPayouts.status === 'success') {
      setKeepMonth(month);
      setStatus('success');
      setPayoutsData(fetchPayouts.results.payouts);
    }

    if (fetchPayouts.status === 'error') {
      const { error: errorStatus } = fetchPayouts;
      if (errorStatus.status === 500) setStatus('unknown_error');
      if (errorStatus.status === 404) {
        setStatus('no_data');
      }
    }

    // Pay artists
    if (payArtists.status === 'success') {
      setLoading(false);
      setError('');
      setStatus('fetching');
      return fetchAction(month, year);
    }

    if (payArtists.status === 'error') {
      setLoading(false);
      return setError(`ERROR: ${payArtists.error.message}`);
    }

    return undefined;
  }, [fetchPayouts, payArtists]);

  const monthsData = monthToLetter();

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      case 'no_data':
        data = (
          <Error
            title="No data found!"
            description="No Payouts data found in this period."
          />
        );
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };
  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  const handlePayments = () => {
    const data = {
      month: month.toString(),
      year: year.toString(),
    };

    setError('');
    setLoading(true);
    return payAction(data);
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    setError('');
    setStatus('fetching');
    setKeepMonth('');
    setKeepYear('');
    return fetchAction(month, year);
  };

  const displayButton = () => {
    const payArray = payoutsData ? payoutsData.filter((pay) => !pay.state) : [];
    const updated =
      status === 'success' ? fetchPayouts.results.balanceUpdated : false;
    const checkMonth = new Date().getMonth() + 1;

    if (
      payArray.length > 0 &&
      checkMonth > keepMonth &&
      status === 'success' &&
      !updated
    ) {
      return (
        <>
          <button
            type="button"
            className="btn btn-outline-primary py-2"
            onClick={handlePayments}
            disabled={!!loading}
          >
            {loading ? (
              <span>
                Paying
                <span className="spinner-border spinner-border-sm text-white ml-2" />
              </span>
            ) : (
              <>
                <span>Pay Artists</span>
                <FontAwesomeIcon
                  icon={['fas', 'money-bill-alt']}
                  className="ml-2"
                />
              </>
            )}
          </button>
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <div className="py-3">
        <div className="row py-2">
          <NumberCard
            col={4}
            count={fetchPayouts.results.pendingPayoutsAmount}
            body="Pending Payments(RWF)"
          />
          <div className="text-center col-12">
            <p
              className="mt-3 text-center text-danger font-weight-bold"
              style={{ fontSize: 15 }}
            >
              {error}
            </p>
          </div>
          <div className="col-12">
            <form className="mt-4">
              <div className="form-row">
                <div className="form-group col-2">
                  <select
                    className={`custom-select bg-primary border-0 text-dark ${
                      status === 'fetching' || loading
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }`}
                    onChange={handleMonthChange}
                    value={month}
                    disabled={!!(status === 'fetching' || loading)}
                  >
                    <option value="0" disabled defaultValue>
                      Month
                    </option>
                    {monthsData.map((select, index) => (
                      <option key={index} value={index + 1}>
                        {select}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-2">
                  <select
                    className={`custom-select bg-primary border-0 text-dark ${
                      status === 'fetching' || loading
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }`}
                    onChange={handleYearChange}
                    value={year}
                    disabled={!!(status === 'fetching' || loading)}
                  >
                    <option value="0" disabled defaultValue>
                      Year
                    </option>
                    {selectData.map((select, index) => (
                      <option key={index}>{select}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-3">
                  <button
                    type="button"
                    className="btn btn-secondary py-2"
                    onClick={handleRefresh}
                    disabled={
                      !!(!year || !month || status === 'fetching' || loading)
                    }
                  >
                    <Icon
                      name="refresh"
                      height={20}
                      width={20}
                      color="#ffffff"
                    />
                  </button>
                </div>
                <div className="form-group col-5 text-right pr-3">
                  {displayButton()}
                </div>
              </div>
            </form>
          </div>
        </div>
        <DisplayData>
          <Table data={payoutsData} />
        </DisplayData>
      </div>
    </>
  );
};

Payouts.propTypes = {
  fetchPayoutsAction: PropTypes.func.isRequired,
  fetchPayouts: PropTypes.objectOf(PropTypes.any).isRequired,
  payArtistsAction: PropTypes.func.isRequired,
  payArtists: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ fetchPayouts, payArtists }) => ({
  fetchPayouts,
  payArtists,
});

export default connect(mapStateToProps, {
  fetchPayoutsAction,
  payArtistsAction,
})(withSidebar(Payouts, 'payouts'));
