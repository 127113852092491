/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UpdateForm from './UpdateForm';

const Modal = ({ id, refetch, data: updateData }) => {
  const closeModal = () => {
    $(`#updatePlaylistModal${id}`).modal('hide');
  };

  return (
    <>
      <button
        className="btn btn-success px-3 py-1 text-dark"
        type="button"
        data-toggle="modal"
        data-target={`#updatePlaylistModal${id}`}
      >
        <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
      </button>
      <div
        className="modal fade"
        id={`updatePlaylistModal${id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`updatePlaylistModal${id}`}
        aria-hidden="true"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ width: '100%' }}
          >
            <div
              className="modal-content bg-secondary text-white border-0 artist-modal"
              style={{ margin: 0 }}
            >
              <div className="modal-header">
                <h6
                  className="modal-title mx-auto text-center"
                  id="addPlaylistModal"
                >
                  Update Playlist&#39;s Info.
                </h6>
                <span
                  onClick={closeModal}
                  className="close-button cursor-pointer text-center"
                >
                  &times;
                </span>
              </div>
              <div className="px-3 pt-0 modal-body">
                <UpdateForm updateData={updateData} refetch={refetch} id={id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Modal;
