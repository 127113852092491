/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import addChartAction from '../../../redux/actions/charts/addChart';

const Modal = ({ addChartAction: addAction, addChart, refetch }) => {
  const [type, setType] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setLoading(false);
    setErrors([]);

    if (addChart.status === 'success') {
      setLoading(false);
      $('#addChartModal').modal('hide');
      setType(0);
      setTitle('');
      setDescription('');
      setErrors([]);
      refetch();
    }
    if (addChart.status === 'error') {
      setLoading(false);
      return setErrors([addChart.error.message]);
    }

    return undefined;
  }, [addChart]);

  const handleSubmit = () => {
    const data = { title, type, description };
    if (!title || !type || !description) {
      return setErrors(['All fields are required']);
    }
    setErrors([]);
    setLoading(true);
    return addAction(data);
  };

  const closeModal = () => {
    $('#addChartModal').modal('hide');
  };

  return (
    <>
      <div
        className="modal fade"
        id="addChartModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addChartModal"
        aria-hidden="true"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ width: '100%' }}
          >
            <div
              className="modal-content bg-secondary text-white border-0 artist-modal"
              style={{ margin: 0 }}
            >
              <div className="modal-header">
                <h6
                  className="modal-title mx-auto text-center"
                  id="addChartModal"
                >
                  Add Chart
                </h6>
                <span
                  onClick={closeModal}
                  className="close-button cursor-pointer text-center"
                >
                  &times;
                </span>
              </div>
              <div className="px-3 pt-0 modal-body">
                {errors.map((error, index) => (
                  <div
                    className="alert alert-danger text-center my-2"
                    role="alert"
                    key={index}
                  >
                    {error}
                    <button
                      type="button"
                      className="close"
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
                <form>
                  <div className="form-group">
                    <select
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      className="custom-select form-control authInput cursor-pointer"
                    >
                      <option disabled defaultValue value={0}>
                        Choose Type
                      </option>

                      <option value="artist">Artist</option>
                      <option value="set">Set</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control authInput"
                      placeholder="Enter chart title"
                      style={{ fontSize: 15 }}
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control authInput"
                      placeholder="Enter description"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      required
                    />
                  </div>
                </form>
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-primary px-3 py-1 ml-2 mt-3"
                    onClick={handleSubmit}
                    disabled={!!loading}
                  >
                    Add
                    {loading ? (
                      <span className="spinner-border spinner-border-sm text-dark ml-2" />
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  refetch: PropTypes.func.isRequired,
  addChartAction: PropTypes.func.isRequired,
  addChart: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ addChart }) => ({ addChart });

export default connect(mapStateToProps, { addChartAction })(Modal);
