/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '../usage/Icon';

const Details = ({ data }) => {
  const bankInfo = data.bank;
  return (
    <>
      <div className="row mx-auto data-div mt-4">
        <div className="col-12">
          <h5
            className="text-left mt-3 mb-4"
            style={{ fontSize: 16, color: '#ccc' }}
          >
            <FontAwesomeIcon
              icon={['fas', 'money-bill-alt']}
              className="auth-form-icon mr-2"
            />
            Bank Information
          </h5>
        </div>

        <div className="col-6 text-left">
          <div className="info-div">
            <span className="text-white d-block">
              <Icon name="success" height={15} width={15} color="#e5da2f" />
              <span className="ml-1">Bank Name</span>
            </span>
            <span
              className="text-primary d-block ml-3"
              style={{ fontSize: 14 }}
            >
              {bankInfo ? bankInfo.bankName : '-'}
            </span>
          </div>
        </div>
        <div className="col-6 text-right">
          <div className="info-div">
            <span className="text-white d-block">
              <Icon name="success" height={15} width={15} color="#e5da2f" />
              <span className="ml-1">Account Number</span>
            </span>
            <span className="text-primary d-block" style={{ fontSize: 14 }}>
              {bankInfo ? bankInfo.accountNumber : '--'}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
Details.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Details;
