import axios from 'axios';

export default axios.create({ baseURL: 'https://api.ingoma.app' });

export const config = {
  headers: {
    ContentType: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('INGOMA_ADMIN_TOKEN')}`,
  },
};
