/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

const DenyModal = ({ action, id }) => {
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = () => {
    const data = { reason };
    if (!reason) {
      return setError("Reason can't be empty!");
    }
    $(`#denyModal${id}`).modal('hide');
    setError('');
    return action(data);
  };

  const closeModal = () => {
    $(`#denyModal${id}`).modal('hide');
  };

  return (
    <div
      className="modal fade"
      id={`denyModal${id}`}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={`denyModal${id}`}
      aria-hidden="true"
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <div className="modal-dialog" role="document" style={{ width: '100%' }}>
          <div
            className="modal-content bg-secondary text-white border-0 artist-modal"
            style={{ margin: 0 }}
          >
            <div className="modal-header">
              <h6
                className="modal-title mx-auto text-center"
                id={`denyModal${id}`}
              >
                Add Reason
              </h6>
              <span
                onClick={closeModal}
                className="close-button cursor-pointer text-center"
              >
                &times;
              </span>
            </div>
            <div className="px-3 pt-0 modal-body">
              <p className="text-center text-danger" style={{ fontSize: 15 }}>
                {error}
              </p>
              <form action="">
                <div className="form-group">
                  <textarea
                    className="form-control authInput"
                    placeholder="Enter denial reason"
                    onChange={(e) => setReason(e.target.value)}
                    value={reason}
                    required
                  />
                </div>
              </form>
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-secondary px-2 py-1 ml-2"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary px-2 py-1 ml-2"
                  onClick={handleSubmit}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DenyModal.propTypes = {
  action: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default DenyModal;
