/* eslint-disable operator-linebreak */
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';
export const FETCH_ARTISTS_SUCCESS = 'FETCH_ARTISTS_SUCCESS';
export const FETCH_ARTISTS_FAILED = 'FETCH_ARTISTS_FAILED';
export const CLEAR_ARTISTS = 'CLEAR_ARTISTS';
export const VERIFY_ARTISTS_SUCCESS = 'VERIFY_ARTISTS_SUCCESS';
export const VERIFY_ARTISTS_FAILED = 'VERIFY_ARTISTS_FAILED';
export const CLEAR_VERIFY_ARTISTS = 'CLEAR_VERIFY_ARTISTS';
export const DENY_ARTISTS_SUCCESS = 'DENY_ARTISTS_SUCCESS';
export const DENY_ARTISTS_FAILED = 'DENY_ARTISTS_FAILED';
export const CLEAR_DENY_ARTISTS = 'CLEAR_DENY_ARTISTS';
export const FETCH_PAYOUTS_SUCCESS = 'FETCH_PAYOUTS_SUCCESS';
export const FETCH_PAYOUTS_FAILED = 'FETCH_PAYOUTS_FAILED';
export const CLEAR_PAYOUTS = 'CLEAR_PAYOUTS';
export const PAY_ARTISTS_SUCCESS = 'PAY_ARTISTS_SUCCESS';
export const PAY_ARTISTS_FAILED = 'PAY_ARTISTS_FAILED';
export const CLEAR_PAY_ARTISTS = 'CLEAR_PAY_ARTISTS';
export const RETRY_PAYOUTS_SUCCESS = 'RETRY_PAYOUTS_SUCCESS';
export const RETRY_PAYOUTS_FAILED = 'RETRY_PAYOUTS_FAILED';
export const CLEAR_RETRY_PAYOUTS = 'CLEAR_RETRY_PAYOUTS';
export const ADD_FEED_SUCCESS = 'ADD_FEED_SUCCESS';
export const ADD_FEED_FAILED = 'ADD_FEED_FAILED';
export const CLEAR_ADD_FEED = 'CLEAR_ADD_FEED';
export const FETCH_ALL_FEED_SUCCESS = 'FETCH_ALL_FEED_SUCCESS';
export const FETCH_ALL_FEED_FAILED = 'FETCH_ALL_FEED_FAILED';
export const CLEAR_FETCH_ALL_FEED = 'CLEAR_FETCH_ALL_FEED';
export const FETCH_FEED_SUCCESS = 'FETCH_FEED_SUCCESS';
export const FETCH_FEED_FAILED = 'FETCH_FEED_FAILED';
export const CLEAR_FETCH_FEED = 'CLEAR_FETCH_FEED';
export const UPDATE_FEED_SUCCESS = 'UPDATE_FEED_SUCCESS';
export const UPDATE_FEED_FAILED = 'UPDATE_FEED_FAILED';
export const CLEAR_UPDATE_FEED = 'CLEAR_UPDATE_FEED';
export const RELEASE_FEED_SUCCESS = 'RELEASE_FEED_SUCCESS';
export const RELEASE_FEED_FAILED = 'RELEASE_FEED_FAILED';
export const CLEAR_RELEASE_FEED = 'CLEAR_RELEASE_FEED';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILED = 'ADD_CATEGORY_FAILED';
export const CLEAR_ADD_CATEGORY = 'CLEAR_ADD_CATEGORY';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILED = 'FETCH_CATEGORIES_FAILED';
export const CLEAR_FETCH_CATEGORIES = 'CLEAR_FETCH_CATEGORIES';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILED = 'UPDATE_CATEGORY_FAILED';
export const CLEAR_UPDATE_CATEGORY = 'CLEAR_UPDATE_CATEGORY';
export const ADD_PLAYLIST_SUCCESS = 'ADD_PLAYLIST_SUCCESS';
export const ADD_PLAYLIST_FAILED = 'ADD_PLAYLIST_FAILED';
export const CLEAR_ADD_PLAYLIST = 'CLEAR_ADD_PLAYLIST';
export const FETCH_PLAYLISTS_SUCCESS = 'FETCH_PLAYLISTS_SUCCESS';
export const FETCH_PLAYLISTS_FAILED = 'FETCH_PLAYLISTS_FAILED';
export const CLEAR_FETCH_PLAYLISTS = 'CLEAR_FETCH_PLAYLISTS';
export const FETCH_PLAYLIST_SUCCESS = 'FETCH_PLAYLIST_SUCCESS';
export const FETCH_PLAYLIST_FAILED = 'FETCH_PLAYLIST_FAILED';
export const CLEAR_FETCH_PLAYLIST = 'CLEAR_FETCH_PLAYLIST';
export const UPDATE_PLAYLIST_SUCCESS = 'UPDATE_PLAYLIST_SUCCESS';
export const UPDATE_PLAYLIST_FAILED = 'UPDATE_PLAYLIST_FAILED';
export const CLEAR_UPDATE_PLAYLIST = 'CLEAR_UPDATE_PLAYLIST';
export const ADD_PLAYLIST_TO_FEED_SUCCESS = 'ADD_PLAYLIST_TO_FEED_SUCCESS';
export const ADD_PLAYLIST_TO_FEED_FAILED = 'ADD_PLAYLIST_TO_FEED_FAILED';
export const CLEAR_ADD_PLAYLIST_TO_FEED = 'CLEAR_ADD_PLAYLIST_TO_FEED';
export const REMOVE_PLAYLIST_TO_FEED_SUCCESS =
  'REMOVE_PLAYLIST_TO_FEED_SUCCESS';
export const REMOVE_PLAYLIST_TO_FEED_FAILED = 'REMOVE_PLAYLIST_TO_FEED_FAILED';
export const CLEAR_REMOVE_PLAYLIST_TO_FEED = 'CLEAR_REMOVE_PLAYLIST_TO_FEED';
export const ADD_TRACKS_SUCCESS = 'ADD_TRACKS_SUCCESS';
export const ADD_TRACKS_FAILED = 'ADD_TRACKS_FAILED';
export const CLEAR_ADD_TRACKS = 'CLEAR_ADD_TRACKS';
export const REMOVE_TRACK_SUCCESS = 'REMOVE_TRACK_SUCCESS';
export const REMOVE_TRACK_FAILED = 'REMOVE_TRACK_FAILED';
export const CLEAR_REMOVE_TRACK = 'CLEAR_REMOVE_TRACK';

export const ADD_CHART_SUCCESS = 'ADD_CHART_SUCCESS';
export const ADD_CHART_FAILED = 'ADD_CHART_FAILED';
export const CLEAR_ADD_CHART = 'CLEAR_ADD_CHART';
export const FETCH_CHARTS_SUCCESS = 'FETCH_CHARTS_SUCCESS';
export const FETCH_CHARTS_FAILED = 'FETCH_CHARTS_FAILED';
export const CLEAR_FETCH_CHARTS = 'CLEAR_FETCH_CHARTS';
export const FETCH_CHART_SUCCESS = 'FETCH_CHART_SUCCESS';
export const FETCH_CHART_FAILED = 'FETCH_CHART_FAILED';
export const CLEAR_FETCH_CHART = 'CLEAR_FETCH_CHART';
export const UPDATE_CHART_SUCCESS = 'UPDATE_CHART_SUCCESS';
export const UPDATE_CHART_FAILED = 'UPDATE_CHART_FAILED';
export const CLEAR_UPDATE_CHART = 'CLEAR_UPDATE_CHART';
export const ADD_CHART_ARTISTS_SUCCESS = 'ADD_CHART_ARTISTS_SUCCESS';
export const ADD_CHART_ARTISTS_FAILED = 'ADD_CHART_ARTISTS_FAILED';
export const CLEAR_ADD_CHART_ARTISTS = 'CLEAR_ADD_CHART_ARTISTS';
export const ADD_CHART_SETS_SUCCESS = 'ADD_CHART_SETS_SUCCESS';
export const ADD_CHART_SETS_FAILED = 'ADD_CHART_SETS_FAILED';
export const CLEAR_ADD_CHART_SETS = 'CLEAR_ADD_CHART_SETS';
export const REMOVE_CHART_ARTIST_SUCCESS = 'REMOVE_CHART_ARTIST_SUCCESS';
export const REMOVE_CHART_ARTIST_FAILED = 'REMOVE_CHART_ARTIST_FAILED';
export const CLEAR_REMOVE_CHART_ARTIST = 'CLEAR_REMOVE_CHART_ARTIST';
export const REMOVE_CHART_SET_SUCCESS = 'REMOVE_CHART_SET_SUCCESS';
export const REMOVE_CHART_SET_FAILED = 'REMOVE_CHART_SET_FAILED';
export const CLEAR_REMOVE_CHART_SET = 'CLEAR_REMOVE_CHART_SET';
export const ADD_CHART_TO_FEED_SUCCESS = 'ADD_CHART_TO_FEED_SUCCESS';
export const ADD_CHART_TO_FEED_FAILED = 'ADD_CHART_TO_FEED_FAILED';
export const CLEAR_ADD_CHART_TO_FEED = 'CLEAR_ADD_CHART_TO_FEED';
export const REMOVE_CHART_FROM_FEED_SUCCESS = 'REMOVE_CHART_FROM_FEED_SUCCESS';
export const REMOVE_CHART_FROM_FEED_FAILED = 'REMOVE_CHART_FROM_FEED_FAILED';
export const CLEAR_REMOVE_CHART_FROM_FEED = 'CLEAR_REMOVE_CHART_FROM_FEED';

export const ADD_PROVIDER_SUCCESS = 'ADD_PROVIDER_SUCCESS';
export const ADD_PROVIDER_FAILED = 'ADD_PROVIDER_FAILED';
export const CLEAR_ADD_PROVIDER = 'CLEAR_ADD_PROVIDER';
export const FETCH_PROVIDERS_SUCCESS = 'FETCH_PROVIDERS_SUCCESS';
export const FETCH_PROVIDERS_FAILED = 'FETCH_PROVIDERS_FAILED';
export const CLEAR_FETCH_PROVIDERS = 'CLEAR_FETCH_PROVIDERS';
export const FETCH_BANKS_SUCCESS = 'FETCH_BANKS_SUCCESS';
export const FETCH_BANKS_FAILED = 'FETCH_BANKS_FAILED';
export const CLEAR_BANKS = 'CLEAR_BANKS';
