/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withSidebar from '../../withSidebar';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import Table from '../../components/Tables/TracksTable';
import fetchPlaylistAction from '../../redux/actions/playlists/fetchPlaylist';
import AddModal from '../../components/modals/tracks/AddTrack';

const Playlist = ({
  fetchPlaylistAction: fetchAction,
  fetchPlaylist,
  match,
}) => {
  const history = useHistory();
  const [status, setStatus] = useState('initial');
  const [resultsData, setResultsData] = useState({});
  const {
    params: { playlistId },
  } = match;

  useEffect(() => {
    if (!localStorage.getItem('INGOMA_ADMIN_TOKEN')) return history.push('/');

    if (status === 'initial') {
      fetchAction(playlistId);
      setStatus('fetching');
    }

    if (fetchPlaylist.status === 'clear') {
      setStatus('fetching');
      setResultsData({});
    }

    if (fetchPlaylist.status === 'success') {
      setStatus('success');
      setResultsData(fetchPlaylist.results);
    }

    if (fetchPlaylist.status === 'error') {
      const { error } = fetchPlaylist;
      if (error.status === 500) setStatus('unknown_error');
      if (error.status === 404) setStatus('no_data');
    }
    return undefined;
  }, [fetchPlaylist]);

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      case 'no_data':
        data = (
          <Error
            title="No data found!"
            description="The playlist doesn't exist!"
          />
        );
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  const refetch = () => {
    setStatus('fetching');
    return fetchAction(playlistId);
  };

  const modalBtn = {
    class: () => {
      if (status === 'success') {
        if (resultsData.type === 'manual') {
          const cname = 'btn-primary text-dark';
          return cname;
        }
        if (resultsData.type === 'generated') {
          const cname = 'btn-info text-white';
          return cname;
        }
      }
      const cname = 'btn-primary text-dark';
      return cname;
    },
    modalId: () => {
      if (status === 'success') {
        if (resultsData.type === 'manual') {
          const cname = '#addTrackModal';
          return cname;
        }
        if (resultsData.type === 'generated') {
          const cname = '#updateTracksModal';
          return cname;
        }
      }
      return '#';
    },
    btnText: () => {
      if (status === 'success') {
        if (resultsData.type === 'manual') {
          const cname = 'Add Tracks +';
          return cname;
        }
        if (resultsData.type === 'generated') {
          const cname = 'Update Tracks';
          return cname;
        }
      }
      return 'Add Tracks +';
    },
  };

  return (
    <>
      <div className="py-3">
        <div className="row py-2 mt-4 mb-3">
          <div className="col-4">
            <p className="text-primary" style={{ fontSize: 16 }}>
              <FontAwesomeIcon icon={['fas', 'text-width']} className="mr-2" />
              Title:
              <span className="ml-2" style={{ fontSize: 14, color: '#fff' }}>
                {status === 'success' ? resultsData.title : '-'}
              </span>
            </p>
            <p className="text-primary" style={{ fontSize: 16 }}>
              <FontAwesomeIcon icon={['fas', 'list-alt']} className="mr-2" />
              Type:
              <span className="ml-2" style={{ fontSize: 14, color: '#fff' }}>
                {status === 'success'
                  ? resultsData.type.charAt(0).toUpperCase() +
                    resultsData.type.slice(1)
                  : '-'}
              </span>
            </p>
            <p className="text-primary" style={{ fontSize: 16 }}>
              <FontAwesomeIcon icon={['fas', 'play-circle']} className="mr-2" />
              No. of Tracks:
              <span className="ml-2 text-white" style={{ fontSize: 14 }}>
                {status === 'success' ? resultsData.number_of_tracks : '0'}
              </span>
            </p>
            <button
              className={`btn ${modalBtn.class()} px-3 py-1`}
              type="button"
              data-toggle="modal"
              data-target={modalBtn.modalId()}
              disabled={status !== 'success'}
            >
              {modalBtn.btnText()}
            </button>
            <AddModal refetch={refetch} playlistId={playlistId} />
          </div>
        </div>
        <DisplayData>
          {status === 'success' && resultsData.number_of_tracks === 0 ? (
            <Error
              title="No data found!"
              description="No tracks added to this playlist!"
            />
          ) : (
            <Table
              data={resultsData.tracks}
              refetch={refetch}
              playlistId={playlistId}
              type={resultsData.type}
            />
          )}
        </DisplayData>
      </div>
    </>
  );
};

Playlist.propTypes = {
  fetchPlaylistAction: PropTypes.func.isRequired,
  fetchPlaylist: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ fetchPlaylist }) => ({ fetchPlaylist });

export default connect(mapStateToProps, { fetchPlaylistAction })(
  withSidebar(Playlist, 'playlists')
);
