import addChart from './addChart';
import fetchCharts from './fetchCharts';
import fetchChart from './fetchChart';
import updateChart from './updateChart';
import addArtists from './addArtists';
import addSets from './addSets';
import removeArtists from './removeArtists';
import removeSets from './removeSets';
import addChartToFeed from './addChartToFeed';
import removeChartFromFeed from './removeChartFromFeed';

export default {
  addChart,
  fetchCharts,
  fetchChart,
  updateChart,
  addArtists,
  addSets,
  removeArtists,
  removeSets,
  addChartToFeed,
  removeChartFromFeed,
};
