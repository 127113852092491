/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import artistPlaceholder from '../../assets/images/placeHolder.png';
import DetailsModal from '../DetailsModal';

const Table = ({ data = [], refetch }) => {
  const [loading, setLoading] = useState(true);

  const imgSrc = (value) => {
    if (loading) {
      return artistPlaceholder;
    }
    if (value) {
      return value;
    }
    return artistPlaceholder;
  };

  return (
    <>
      <div className="border rounded">
        <table className="table text-white text-center mb-0">
          <thead>
            <tr>
              <th scope="col">Picture</th>
              <th scope="col">Artist Name</th>
              <th scope="col">Phone</th>
              <th scope="col">Status</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {data.map((artist) => (
              <tr key={artist._id}>
                <th label="image" scope="row">
                  <img
                    className="rounded-circle"
                    width="40px"
                    height="40px"
                    style={{ objectFit: 'cover' }}
                    src={imgSrc(artist.profilePhotoLink)}
                    alt="Artist Pic"
                    srcSet=""
                    onLoad={() => setLoading(false)}
                  />
                </th>
                <td>{artist.name}</td>
                <td>{artist.phoneNumber}</td>
                <td>{artist.status}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-dark px-4 py-1"
                    data-toggle="modal"
                    data-target={`#detailsModal${artist._id}`}
                  >
                    Details
                  </button>
                  <DetailsModal
                    data={artist}
                    id={artist._id}
                    refetch={refetch}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any]))
  ).isRequired,
  refetch: PropTypes.func.isRequired,
};

export default Table;
