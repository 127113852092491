import axios, { config } from '..';
import {
  REMOVE_PLAYLIST_TO_FEED_SUCCESS,
  REMOVE_PLAYLIST_TO_FEED_FAILED,
} from '../../actionTypes';

export default (id, payload) => async (dispatch) => {
  try {
    const response = await axios.put(
      `/feed-playlists/${id}/remove-from-feed`,
      payload,
      config
    );

    const {
      data: { message },
    } = response;
    dispatch({
      type: REMOVE_PLAYLIST_TO_FEED_SUCCESS,
      message,
    });
  } catch (err) {
    let error = {};
    if (err.response) {
      const {
        data: { status, message },
      } = err.response;
      error = { status, message };
    } else {
      error = {
        status: 500,
        message: err.message,
      };
    }
    dispatch({ type: REMOVE_PLAYLIST_TO_FEED_FAILED, error });
  }
};
