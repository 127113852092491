/* eslint-disable object-curly-newline */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../assets/images/logo.png';
import placeholder from '../assets/images/user.png';
import Icon from './usage/Icon';
import logOutAction from '../redux/actions/admin/logout';

const SideBar = ({ active, logOutAction: logOut, admin }) => {
  useEffect(() => {
    if (admin.status === 'logout_success') {
      return window.location.assign('/login');
    }
    return undefined;
  }, [admin]);

  const handleLogOut = () => {
    localStorage.removeItem('INGOMA_ADMIN_TOKEN');
    return logOut();
  };

  return (
    <div className="sidebar">
      <div className="logo-container">
        <img src={logo} className="sidebar-logo" alt="logo" />
      </div>
      <div className="sidebar-content">
        <div className="provider">
          <div className="provider-initials yellow">
            <img
              className="img-fluid rounded-circle my-3"
              src={placeholder}
              style={{ height: '140px', width: '140px', objectFit: 'cover' }}
              alt="Initials Pict"
            />
          </div>
          <div className="larger-text mt-1">Welcome</div>
          <div className="small-text yellow">Admin</div>
        </div>
        <div className="sidebar-menu">
          <Link to="/" className="menu-item mb-2">
            <Icon
              name="music"
              height={25}
              width={25}
              color={active === 'artists' ? '#E5DA2F' : '#FFFFFF'}
            />
            <span
              className={`ml-2 ${active === 'artists' ? 'yellow' : 'white'}`}
            >
              Artists
            </span>
          </Link>
          <Link to="/providers" className="menu-item mb-2">
            <FontAwesomeIcon
              icon={['fas', 'user-check']}
              className={active === 'providers' ? 'yellow' : 'white'}
              style={{ fontSize: 20 }}
            />
            <span
              className={`ml-2 ${active === 'providers' ? 'yellow' : 'white'}`}
            >
              Providers
            </span>
          </Link>

          <Link to="/payouts" className="menu-item mb-2">
            <Icon
              name="payouts"
              height={25}
              width={25}
              color={active === 'payouts' ? '#E5DA2F' : '#FFFFFF'}
            />
            <span
              className={`ml-2 ${active === 'payouts' ? 'yellow' : 'white'}`}
            >
              Payouts
            </span>
          </Link>
          <Link to="/feed" className="menu-item mb-2">
            <FontAwesomeIcon
              icon={['fas', 'home']}
              className={active === 'feed' ? 'yellow' : 'white'}
              style={{ fontSize: 20 }}
            />
            <span className={`ml-2 ${active === 'feed' ? 'yellow' : 'white'}`}>
              Feed
            </span>
          </Link>
          <Link to="/playlists" className="menu-item mb-2">
            <FontAwesomeIcon
              icon={['fas', 'play-circle']}
              className={active === 'playlists' ? 'yellow' : 'white'}
              style={{ fontSize: 20 }}
            />
            <span
              className={`ml-2 ${active === 'playlists' ? 'yellow' : 'white'}`}
            >
              Playlists
            </span>
          </Link>
          <Link to="/charts" className="menu-item mb-4">
            <FontAwesomeIcon
              icon={['fas', 'chart-bar']}
              className={active === 'charts' ? 'yellow' : 'white'}
              style={{ fontSize: 20 }}
            />
            <span
              className={`ml-2 ${active === 'charts' ? 'yellow' : 'white'}`}
            >
              Charts
            </span>
          </Link>
          <div className="hr" />
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary py-0 px-4 mb-3"
        onClick={handleLogOut}
      >
        <Icon name="logout" height={24} width={24} color="#232323" />
        Log Out
      </button>
    </div>
  );
};

SideBar.propTypes = {
  active: PropTypes.string.isRequired,
  logOutAction: PropTypes.func.isRequired,
  admin: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ admin }) => ({ admin });

export default connect(mapStateToProps, { logOutAction })(SideBar);
