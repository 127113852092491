/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '../usage/Icon';

const Details = ({ data }) => (
  <>
    <div className="row mx-auto data-div mt-2">
      <div className="col-12">
        <h5
          className="text-left mt-3 mb-4"
          style={{ fontSize: 16, color: '#ccc' }}
        >
          <FontAwesomeIcon
            icon={['fas', 'user-alt']}
            className="auth-form-icon mr-2"
          />
          Personal Information
        </h5>
      </div>

      <div className="col-6 text-left">
        <div className="info-div">
          <span className="text-white d-block">
            <Icon name="success" height={15} width={15} color="#e5da2f" />
            <span className="ml-1">Firstname</span>
          </span>
          <span className="text-primary d-block ml-3" style={{ fontSize: 14 }}>
            {data.firstname}
          </span>
        </div>
        <div className="info-div mt-3">
          <span className="text-white d-block">
            <Icon name="success" height={15} width={15} color="#e5da2f" />
            <span className="ml-1">Lastname</span>
          </span>
          <span className="text-primary d-block ml-3" style={{ fontSize: 14 }}>
            {data.lastname}
          </span>
        </div>
      </div>
      <div className="col-6 text-right">
        <div className="info-div">
          <span className="text-white d-block">
            <Icon name="success" height={15} width={15} color="#e5da2f" />
            <span className="ml-1">Phone Number</span>
          </span>
          <span className="text-primary d-block" style={{ fontSize: 14 }}>
            {data.phoneNumber}
          </span>
        </div>
        <div className="info-div mt-3">
          <span className="text-white d-block">
            <Icon name="success" height={15} width={15} color="#e5da2f" />
            <span className="ml-1">Email</span>
          </span>
          <span className="text-primary d-block" style={{ fontSize: 14 }}>
            {data.email}
          </span>
        </div>
      </div>
    </div>
  </>
);

Details.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Details;
